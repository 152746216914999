<template>
    <div class="dark-bg fullwidth banner gradient-overlay custom">
        <div class="container">
            <h2>Career in Quantuum Technologies</h2>
            <p>Explore jobs' opportunities on qt.eu</p>
            <a class="btn" href="https://qt.eu/about-quantum-flagship/jobs-quantum-technologies" target="_blank">
                Go to Qt.eu website
            </a>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'banner-hp',

    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .fullwidth.banner.custom {
        height: auto;
        background: #01036E url("../assets/bg-banner-hp.jpg");
        background-position: center right;
        background-size: auto 100%;
        background-repeat: no-repeat;

    }

    .fullwidth.banner.custom > .container {
        top: auto;
        transform: none;
    }   

    .gradient-overlay {
        position: relative;
    }

    .gradient-overlay:after {
        background-image: linear-gradient(36deg, #ff05764d, #01036ebc);
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        opacity: 1 ;
    }

    .gradient-overlay:before {
        background-image: url("../../src/assets/filigrana-dotted-lines.png");
        background-position: bottom left;
        background-size: 100%;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        content: "";
        left: 0;
        opacity: 1;
        background-repeat: no-repeat;
    }


    .dark-bg a:not(.plain),
    .btn {
        padding: 10px 2em;
        margin: 0.5em 0;
        display: block;
        border: 2px transparent solid;
        text-decoration: none;
        background: linear-gradient(to right, #FF7A07 20%, #FF0576 80%) padding-box, linear-gradient(to right, #FF7A07 20%, #FF0576 80%) border-box;
        background-repeat: space;
        min-width: 130px;
        text-align: center;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 1rem;
        border-radius: 50px;
    }


    .fullwidth.banner.custom {
        p {
            margin: 20px 0px 40px 0px;
            color: #fff;
            font-size: 18px;
            font-weight: 100;
            opacity: 1;
        }
    }


    @media (min-width:992px) {
        .gradient-overlay:before {
            background-size: 770px;
        }
    }

    @media (min-width:1024px) {
        .fullwidth.banner.custom p {
            font-size: 22px;
        }
        .fullwidth.banner.custom {
            padding: 4em 0;
        }
    }
</style>
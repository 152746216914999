<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">
    
    <HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container">

      <div class="page-body blue-title">
        <h2>Error 404 - not found</h2>
        <p>The content you are looking for does not exist <br> or it's in Work In Progress status.</p>
      </div>

    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'not-found',
  components: {
    HeaderIntroImage
  },
  data() {
    return {
      pageData: {
        attributes: {
          title: ""
        }
      },
      currentPageID: ""
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="page-layout program-courses">

    <HeaderIntroImage underlined central :categoryData="categoryData" :headerMetadata="pageData">
      <!--
        <br>
      <p class="teaser" v-if="pageSubtitle!=''">{{ this.pageSubtitle }}</p>
      <br> -->
      <button class="slim btn" @click="goto()"><span>Submit</span></button>
    </HeaderIntroImage>

    <div class="container">
      <br><br>
      <div class="list-wrapper">

        <FiltersAside @reset="reset()">
          <button :class="['filters--btn', typeFilter.includes('Program') ? 'active' : '']" @click="toggleFilter('Program')"><span>Programs</span></button>
          <button :class="['filters--btn', typeFilter.includes('Course') ? 'active' : '']" @click="toggleFilter('Course')"><span>Courses</span></button>
          <button :class="['filters--btn', typeFilter.includes('Training') ? 'active' : '']" @click="toggleFilter('Training')"><span>Trainings</span></button>
         
          <button :class="['filters--btn', onlineFilter ? 'active' : '']" @click="toggleOnline"><span>Online Activities</span></button>
          <button :class="['filters--btn', opportunityFilter ? 'active' : '']" @click="toggleOpportunity"><span>Internship Opportunities</span></button>
          <button :class="['filters--btn', free ? 'active' : '']" @click="toggleFree"><span>FREE</span></button>

          <select name="country" id="" class="filters--dropdown" @input="addLang">
            <option value="all">Filter by Language</option>
            <option v-for="(l, ind) in langList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>

          <div class="active-filter icon-right close" v-for="(l, ind) in langFilter" :key="'lang'+ind" @click="removeLang(l)">{{ l }}</div>
          <select name="country" id="" class="filters--dropdown" @input="addTarget">
            <option value="all">Filter by Target</option>
            <option v-for="(l, ind) in targetList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>

          <div class="active-filter icon-right close" v-for="(l, ind) in targetFilter" :key="'targ'+ind" @click="removeTarget(l)">{{ l }}</div>
          <select name="country" id="" class="filters--dropdown" @input="addLocation">
            <option value="all">Filter by Location</option>
            <option v-for="(l, ind) in locationList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
        
          <div class="active-filter icon-right close" v-for="(l, ind) in locationFilter" :key="'location'+ind" @click="removeLocation(l)">{{ l }}</div>

          <!-- <select name="isFreePaid" class="filters--dropdown" @input="addFreePaid">
            <option value="all">Filter by Free???</option>
            <option v-for="(l, ind) in freePaidList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          
          <div class="active-filter icon-right close" v-for="(l, ind) in freePaidFilter" :key="'targ'+ind" @click="removeFreePaid(l)">{{ l }}</div> -->

        
        </FiltersAside>

        <div v-if="this.loading">
          <br>
          <h3>Loading data ...</h3>
          <br>
        </div>

        <ArticlesList v-else nodetype="generic--list">
          <div class="no-data" v-if="filteredPCTs.length==0">
            <h3>No Data</h3>
          </div>
          <div v-else>
            <h4 class="nitems"><span>{{ filteredPCTs.length }}</span> items</h4>
          </div>

          <PCTCard v-for="(pct, index) in this.filteredPCTs" :key="'pct'+index"
            :articleData="pct" :routesData="routesData" :cat="categoryData"
            :pinned="pct.attributes.field_internship_opportunity"
            />
        </ArticlesList>
      </div>
    </div>

  </div>
</template>

<script>
import FiltersAside from '../components/layout-blocks/FiltersAside.vue'
import PCTCard from '../components/layout-blocks/PCTCard.vue'
import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'programs-courses-and-trainings',
  components: {
    HeaderIntroImage,
    FiltersAside,
    PCTCard,
    ArticlesList
  },
  props: {},
  data: () => {
    return {
      pageData: null,
      currentPageID: "865ca4fd-af2c-410e-af68-05976541b098",
      programData: [],
      typeFilter: [],
      onlineFilter: false,
      free: false,
      opportunityFilter: false,
      targetFilter: [],
      locationFilter: [],
      langFilter: [],
      loading: true,
      freePaidFilter: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    category() {
      return this.$route.params.title
    },
    routesData() {
      return this.$store.state.routesData
    },
    langList() {
      var lang = []

      if(this.programData) {
        this.programData.forEach(m =>{
          if(m.field_program_language)
            m.field_program_language.forEach(l => {
              if(l && !lang.includes(l.attributes.name))
                lang.push(l.attributes.name)
            })
        })
      }

      return lang.sort((a,b) => { return a>b ? 1 : -1 })
    },
    targetList() {
      var tg = []

      if(this.programData) {
        this.programData.forEach(m =>{
          if(m.field_school_target)
            m.field_school_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
          if(m.field_higher_education_target)
            m.field_higher_education_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
          if(m.field_life_long_learning_target)
            m.field_life_long_learning_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
        })
      }

      return tg.filter(t => t!='Other (specify)').sort((a,b) => { return a>b ? 1 : -1 })
    },
    locationList() {
      var loc = []

      if(this.programData) {
        this.programData.forEach(m => {
          if(m.attributes.field_country)
            m.attributes.field_country.forEach(c => {
              if(!loc.includes(this.country(c.country)))
                loc.push(this.country(c.country))
            })
        })
      }

      return loc.sort((a,b) => { return a>b ? 1 : -1 })
    },
    freePaidList() {
      let free = ["Is it Free"]
      return free
    },
    filteredPCTs() {
      var pcts = []

      if(this.programData) {
        pcts = this.programData

        if(this.opportunityFilter)
          pcts = pcts.filter(p => p.attributes.field_internship_opportunity)

        if(this.onlineFilter)
          pcts = pcts.filter(p => p.attributes.field_online_activity)

        if(this.free)
          pcts = pcts.filter(p => p.attributes.field_is_the_program_free)

        if(this.typeFilter.length) {
          pcts = pcts.filter(m => {
            var found = false
            if(this.typeFilter.includes(m.field_category.attributes.name))
                found = true
            return found
          })
        }

        if(this.langFilter.length) {
          pcts = pcts.filter(m => {
            var found = false
            m.field_program_language.forEach(t => {
              if(this.langFilter.includes(t.attributes.name))
                found = true
            })
            return found
          })
        }

        if(this.locationFilter.length) {
          pcts = pcts.filter(m => {
            var found = false
            /* m.field_program_organization.forEach(t => {
              if(t.attributes.field_org_mat_prg_country
                && this.locationFilter.includes( this.country(t.attributes.field_org_mat_prg_country.country) ))
                found = true
            }) */
            m.attributes.field_country.forEach(t => {
              if(t.country
                && this.locationFilter.includes( this.country(t.country) ))
                found = true
            })
            return found
          })
        }

        if(this.targetFilter.length) {
          pcts = pcts.filter(m => {
            var found = false
            m.field_school_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            m.field_higher_education_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            m.field_life_long_learning_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            return found
          })
        }

        // if(this.freePaidFilter.length) {
        //   pcts = pcts.filter(m => {
        //     var found = false
        //     if (m.attributes.field_is_the_program_free != null){
        //       found = true
        //     }
        //     return found
        //   })
        // }

      }

      return pcts
    },
    /* pageSubtitle() {
      switch(this.categoryData.nid) {
        case 14:
          return "Our collection includes different types of education opportunities focused on Quantum Technology hosted by the education providers around Europe. They are targeting primary and secondary schools and include programs, courses, modules, workshops, ect."
        case 15:
          return "Our collection includes different types of education opportunities focused on Quantum Technology hosted by the education providers around Europe. They are targeting Bachelor’s, Master’s and PhD students and include programs, courses, modules, workshops, ect."
        case 16:
          return "Our collection includes different types of education opportunities focused on Quantum Technology hosted by the education providers around Europe. They are targeting entrepreneurs, managers, investors, professionals, technicians and others and include programs, courses, modules, workshops, ect." 
        default:
          return ""
      }
    }, */
    categoryData() {
      var cd = {
        nid: 0,
        name: "",
        pageid: ""
      }

      switch(this.category) {
        case 'primary-and-secondary-school':
          cd.name = "Primary and Secondary School"
          cd.pageid = "229085b8-4699-41b8-841e-ffb71c24e747"
          cd.nid = 14
          break;
        case 'higher-education':
          cd.name = "Higher Education"
          cd.pageid = "083f8557-e4c4-48cd-8823-92a103a198e6"
          cd.nid = 15
          break;
        case 'lifelong-learning-and-retraining':
          cd.name = "Lifelong Learning and (re)training"
          cd.pageid = "397611c9-ba81-4d17-9fc3-460a4147fe44"
          cd.nid = 16
          break;
        case 'outreach':
          cd.name = "Outreach"
          cd.pageid = "7698bf43-3a01-4815-87dd-9ee6e1025fad"
          cd.nid = 17
          break;
        default:
          cd.name = ""
          cd.nid = 0
          cd.pageid = ""
          break;
      }

      return cd
    }
  },
  methods: {
    goto() {
      this.$router.push('/get-involved')
    },
    country(c) {
      return this.$store.getters.getCountry(c)
    },
    toggleFilter(name) {
      if(!this.typeFilter.includes(name))
        this.typeFilter.push(name)
      else 
        this.typeFilter.splice(this.typeFilter.indexOf(name), 1)
    },
    addLang(event) {
      if(!this.langFilter.includes(event.target.value))
        this.langFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeLang(l) {
      var ind = this.langFilter.indexOf(l)
      this.langFilter.splice(ind, 1)
    },
    addTarget(event) {
      if(!this.targetFilter.includes(event.target.value))
        this.targetFilter.push(event.target.value)

      event.target.value = 'all'
    },
    addFreePaid(event) {
      if(!this.freePaidFilter.includes(event.target.value))
        this.freePaidFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeTarget(l) {
      var ind = this.targetFilter.indexOf(l)
      this.targetFilter.splice(ind, 1)
    },
    addLocation(event) {
      if(!this.locationFilter.includes(event.target.value))
        this.locationFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeLocation(l) {
      var ind = this.locationFilter.indexOf(l)
      this.locationFilter.splice(ind, 1)
    },
    reset() {
      this.targetFilter = []
      this.locationFilter = []
      this.typeFilter = []
      this.langFilter = []
      this.onlineFilter = false
      this.free = false
      this.opportunityFilter = false
    },
    toggleOpportunity() {
      this.opportunityFilter = !this.opportunityFilter
    },
    toggleOnline() {
      this.onlineFilter = !this.onlineFilter
    },
    toggleFree() {
      this.free = !this.free
    },
    async findCT() {
      this.loading = true

      await fetchSinglePage(this.categoryData.pageid, {})
      .then(res => this.pageData = res )

      await fetchNodes('program', {
        include: ['field_category', 'field_higher_education_target', 'field_level',
        'field_life_long_learning_target', 'field_school_target', 'field_program_language',
        'field_program_organization', 'field_program_topic', 'field_ref_member_2']
      })
      .then(res => {
        this.programData = res.filter(p => {
          var found = false
         //console.log('p',p);
          p.field_level.forEach(f => {
            //console.log(f.attributes.name)
            if(f.attributes.name.toLowerCase() == this.categoryData.name.toLowerCase())
              found = true
          })
          return found
        })
        //console.log('pct', this.programData)
      })

      this.loading = false
    }
  },
  async mounted() {
    this.loading = true

    this.reset()

    if(this.categoryData.name=="")
      this.$router.push('/not-found')

    await this.findCT()

    if(this.$store.state.temp_internship)
      this.opportunityFilter = true

    this.$store.commit('SET_INTERNSHIP', false)
  },
  watch: {
    async category() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.nitems {
  margin: 0 0 0 auto;
  span { font-weight: 700; }
}

.active-filter {
  padding: .25em;
  font-size: 14px;
  padding-right: 1.5em;
  color: $middleBlue;
  &:before { padding-right: .5em; }
  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
}
</style>
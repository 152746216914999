<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">
    
    <HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container">

      <div class="page-body blue-title">
        <h2>Logging out</h2>
        <p>You've been log out from you personal account and/or dashboard.</p>
      </div>

    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'logout',
  components: {
    HeaderIntroImage
  },
  data() {
    return {
      pageData: {
        attributes: {
          title: ""
        }
      },
      currentPageID: ""
    }
  },
  mounted() {
    this.$store.commit('SET_LOGOUT')
   //console.log('*** LOGGED OUT ***')
    window.location.href = process.env.VUE_APP_REDIRECT + 'user/logout'
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="homepage">
    <header>
      <VueSlickCarousel v-bind="slideshowSettings" v-if="this.slideshow.length > 0">
        <div v-for="(slide,index) in slideshow" :key="index" class="slide">

          <div class="bg-image">
            <img :src="endpoint + slide.field_immagine.attributes.uri.url" alt="">
          </div>

          <div class="container">
            <h1>{{ slide.attributes.title }}</h1>
            <p>{{ slide.attributes.field_slide_subtitle.value }}</p>

            <div v-if="slide.attributes.field_url">
              <!-- <a v-for="(link, index) in slide.attributes.field_url" :key="index" target="_blank" :href="link.uri">View details</a> -->
              <a :href="slide.attributes.field_url.uri" class="details"><span>View details</span></a>
            </div>
          </div>
        </div>
        
      </VueSlickCarousel>
      
    </header>

    <section class="the-project">
      <div class="container">
        <div class="col-img">
          <img src="../assets/home-place.jpg" alt="">
        </div>
        <div class="col-text">
          <h2>The QTEdu Project</h2>
          <p>
            The purpose of this project is to assist the European Quantum Flagship with the creation of the
            learning ecosystem necessary to inform and educate society about quantum technologies. The goal
            is that a quantum-ready society, with knowledge about and positive attitudes towards quantum
            technologies, will enable the emergence of a quantum-ready workforce.
            <br>
            The QTEdu CSA Project is funded by the European Union’s Horizon 2020 research and innovation programme under
            grant agreement number 951787.
          </p>
          <div class="dark-bg">
            <router-link to="/why-qtedu-csa">Discover more</router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- THE WORKING GROUPS SECTION WITH CIRCLES -->
    <section class="working-groups-hp">
      <div class="container">
        <h2 class="pink">The Working Groups</h2>
        <p>
          We are bringing together quantum professionals from different walks of quantum education, research, and
          industry
          to help define the future of Europe’s quantum workforce as the second quantum revolution unfolds.
        </p>

        <div class="circle-container" v-if="this.wgData">
          <div class="circle" v-for="(wg, index) in this.wgData" :key="'working-group'+index">
            <router-link class="circle-link plain" :to="wglink(wg)">
              <div :class="['circle-icon', 'target'+wg.attributes.drupal_internal__nid]"></div>
              <h2>{{ wg.attributes.title }}</h2>
              <div :class="['circle-icon', 'arrow']"></div>
            </router-link>
          </div>
        </div>
      </div>

      <br>
      <br>
    </section>

    <!-- NEWS & CALLS TO ACTION SECTION -->
    <section class="gray-bg">

      <div class="container title-container">
        <h2>News & Calls to Action</h2>
        <router-link class="icon-right long-arrow" to="/news">View all News</router-link>
      </div>

      <div>
        <BlockCarousel vw v-if="callsData && newsData">
          <CallToActionCard v-for="(n, index) in this.callsData" class="calls" :key="'calls'+index" :articleData="n"
            :routesData="routesData" :cat="categoryData(n)" />
          
          <keep-alive v-for="(n, index) in this.newsData" :key="'news'+index">
            <ArticleCard v-if="n.attributes.field_news_type==1" :articleData="n" :routesData="routesData" :cat="categoryData(n)" />
            <CallToActionCard v-if="n.attributes.field_news_type==2" pastcall :articleData="n" :routesData="routesData" :cat="categoryData(n)" />
          </keep-alive>
        </BlockCarousel>
      </div>
    </section>

    <HomepageMapbox />

    <section class="resources-for-everyone">
      <div class="container">
        <h2>Resources for everyone</h2>
        <div class="box-container">
          <div class="box">
            <h3>Material and Tools</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/material-and-tools/primary-and-secondary-school" class="icon-left target14">School education and Public outreach</router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/material-and-tools/higher-education" class="icon-left target15">Higher Education</router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/material-and-tools/lifelong-learning-and-retraining" class="icon-left target16">Lifelong
                  learning and training</router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/material-and-tools/outreach" class="icon-left target17">Outreach general public</router-link>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>Evaluation Tools</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/evaluation-tools/primary-and-secondary-school" class="icon-left target14">School education and Public outreach</router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/evaluation-tools/higher-education" class="icon-left target15">Higher Education
                </router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/evaluation-tools/lifelong-learning-and-retraining" class="icon-left target16">Lifelong
                  learning and training</router-link>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>Programs, courses and trainings</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/programs-courses-and-trainings/primary-and-secondary-school" class="icon-left target14">School education and Public outreach</router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/programs-courses-and-trainings/higher-education" class="icon-left target15">Higher Education
                </router-link>
              </li>
              <li class="icon-right arrow">
                <router-link to="/programs-courses-and-trainings/lifelong-learning-and-retraining" class="icon-left target16">Lifelong
                  learning and (re)training</router-link>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>EU School Curricula Tools</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/european-school-quantum-physics-curricular" class="icon-left target14">School education and Public outreach</router-link>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>Internships</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/internship-opportunities" class="icon-left target15">Higher Education</router-link>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>Competence Framework</h3>
            <ul>
              <li class="icon-right arrow">
                <router-link to="/european-competence-framework-quantum-technologies" class="icon-left tools">Tools for builders & Policymakers</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <banner-hp></banner-hp>

  </div>
</template>

<script>
  import BannerHp from '../components/BannerHp.vue'
  import BlockCarousel from '../components/layout-blocks/BlockCarousel.vue'
  import ArticleCard from '../components/layout-blocks/ArticleCard.vue'
  import CallToActionCard from '../components/layout-blocks/CallToActionCard.vue'
  import HomepageMapbox from '../components/HomepageMapbox.vue'
  import {
    fetchNodes
  } from '../libs/drupalClient'
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default {
    name: 'home',
    components: {
      BlockCarousel,
      ArticleCard,
      CallToActionCard,
      HomepageMapbox,
      VueSlickCarousel,
      BannerHp
    },
    data: () => {
      return {
        wgData: null,
        newsData: null,
        callsData: null,
        slideshow: [],
        slideshowSettings: {
          arrows: false,
          dots: true,
          currentIndex: 0,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToScroll: 1,
        },
      }
    },
    computed: {
      routesData() {
        return this.$store.state.routesData
      },
      endpoint() {
        return process.env.VUE_APP_ENDPOINT
      },
    },
    methods: {
      categoryData(n) {
        var cd = {
          nid: 0,
          name: ""
        }

        if (n.field_ref_working_group)
          switch (n.field_ref_working_group.attributes.drupal_internal__nid) {
            case 14:
              cd.nid = 14
              cd.name = 'School education and Public outreach'
              break;
            case 15:
              cd.nid = 15
              cd.name = 'Educational initiatives in higher education'
              break;
            case 16:
              cd.nid = 16
              cd.name = 'Lifelong learning and workforce training'
              break;
            case 17:
              cd.name = "Educational research in Quantum Technology"
              cd.nid = 17
              break;
            case 18:
              cd.nid = 18
              cd.name = 'Equity and inclusion for QT educational initiatives'
              break;
            default:
              cd.nid = 0
              break;
          }

        return cd
      },
      wglink(wg) {
        var url = "/working-group"
        switch (wg.attributes.drupal_internal__nid) {
          case 14:
            url += "/school-education-and-public-outreach"
            break;
          case 15:
            url += "/educational-initiatives-higher-education"
            break;
          case 16:
            url += "/lifelong-learning-and-workforce-training"
            break;
          case 17:
            url += "/educational-research-quantum-technology"
            break;
          case 18:
            url += "/equity-and-inclusion-qt-educational-initiatives"
            break;
          default:
            url = "/not-found"
            break;
        }
        return url
      }
    },
    async mounted() {
      await fetchNodes('slide', {
          include: ['field_immagine']
        })
        .then(res => {
          this.slideshow = res.sort((a,b) => {
            return a.attributes.field_slide_order > b.attributes.field_slide_order ? 1 : -1
          })
          /*//console.log('res slide', this.slideshow); */
        })

      await fetchNodes('working_group', {})
        .then(res => {
          this.wgData = res
        })

      await fetchNodes('news', {
          include: ['field_image', 'field_ref_project', 'field_ref_working_group']
        })
        .then(res => {
          var sorted = res.sort((a, b) => {
            return a.attributes.field_date > b.attributes.field_date ? -1 : 1
          })

          this.newsData = sorted.filter(n => 
          n.attributes.field_news_type == 1
          || (n.attributes.field_news_type==2 && new Date(n.attributes.field_opportunity_deadline) < Date.now())
          ).slice(0, 6)
          this.callsData = sorted.filter(n => 
            n.attributes.field_news_type==2 
            && new Date(n.attributes.field_opportunity_deadline) > Date.now()
          ).slice(0, 1)
        })
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/variables.scss";

  .details:hover { color: #fff; background: none; background-clip: unset; }

  body .homepage .the-project a:not(.plain) {
    font-weight: 600;

    &:hover {
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
    }
  }

  .title-container {
    margin-bottom: 1rem;

    a {
      display: block;
    }

    .icon-right.long-arrow:before {
      right: 0;
      left: auto;
    }
  }


  header {
    height: 93vh;
    background: #071152;
    position: relative;

    .slide {
      position: relative;
      height: 100%;
      display: inline-flex !important;
      align-items: flex-end;
    }

    .bg-image {
      position: absolute;
      top: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: $primaryColor;

      &:after {
        background: linear-gradient(#07115236 33%, #071152e6 91%, #071152 99%);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
        content: "";
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    &:after {
      background: linear-gradient(#07115200, $primaryColor);
      width: 100%;
      height: 430px;
      bottom: 0;
      left: 0;
      content: "";
      position: absolute;
      z-index: 0;
    }

    &::before {
      content: url("../assets/filigrana-hp.png");
      position: absolute;
      right: 0;
      top: 30%;
      z-index: 9;
      pointer-events: none;
    }

    .slick-slider {
      position: relative;
      z-index: 1;
      margin: 0;
      width: 100%;
      height: 100%;

      ::v-deep {
        .slick-list {
          height: 100%;
          margin: 0;
          padding: 0;
          top: 0;
        }

        .slick-track {
          display: flex;
          height: 100%;
          align-items: end;
        }
      }
    }

    ::v-deep .slick-dots {
      background: $primaryColor;
      top: 94vh;
      z-index: 10;
    }

    ::v-deep .slick-dots li button::before,
    ::v-deep .slick-dots li.slick-active button::before {
      color: #fff;
    }

    h1 {
      color: #fff;
      font-size: 3.125rem;
      line-height: 3.5rem;
      font-weight: 300;
    }

    p {
      color: #fff;
    }
  }

  section {
    padding: 3rem 0rem;
  }

  h2 {
    margin-top: 0;
  }

  .the-project {
    background-color: $primaryColor;
    position: relative;
    //z-index: 9;
    /* padding: 0 0 3em 0;
    margin: 3em 0 0 0; */

    &:after {
      background-color: #fff;
      width: 100%;
      height: 100px;
      bottom: 0;
      left: 0;
      content: "";
      display: none;
      position: absolute;
      z-index: 0;
    }

    


    >div {
      display: flex;
      flex-direction: column;
      

      >div {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .col-text {
      padding-left: 0rem;
      //z-index: 10;
    }

    .col-img {
      position: relative;
      z-index: 1;
      margin-bottom: 1rem;
    }

    h2 {
      color: #fff;
      font-weight: 300;
      font-size: 1.875rem;
    }

    p {
      color: #fff;
    }
  }

  .resources-for-everyone {
    background-color: #EEF2F9;

    h2 {
      color: #01036E;
      font-size: 1.875rem;
      line-height: 2rem;
      margin-bottom: 2rem;
      font-weight: 400;
    }
  }

  .box-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;

    .box {
      width: 100%;
      background-color: #fff;
      padding: 36px 20px 20px 20px;
      position: relative;
      border-radius: 0px 6px 6px 0px;
      box-shadow: 4px 4px 24px 0px #0000001c;

      h3 {
        margin-top: 0;
        font-size: 1.625rem;
        font-weight: 400;
      }

      &:after {
        background: $vgradient;
        width: 100%;
        height: 7px;
        left: 0;
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: auto;
      }

      li {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin: 1em 0;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 15px;
        }
      }

      a {
        color: $primaryColor;
        background-image: none;
        background-clip: unset;
        -webkit-text-fill-color: unset;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: $secondaryColor;
        }
      }

    }
  }

  .homepage .block-carousel ::v-deep .slick-arrow.slick-prev {
    left: 70px;
  }

  .homepage .block-carousel ::v-deep .slick-arrow.slick-next {
    right: 70px;
  }

  @media (min-width: 768px) {

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        padding-right: 80px;
        display: inline-block;
        width: auto;
      }
      .icon-right.long-arrow:before {
        right: -20px
      }
    }



    .homepage header ::v-deep .slick-slide>div {
      margin: 0;
    }

  }

  @media (min-width: $container-breakpoints-md) {
    header {
      background-position-y: 25%;
    }

    .box-container {
      grid-template-columns: 1fr 1fr;

      .box {
        padding: 26px 34px;

        &:after {
          width: 7px;
          height: 100%;
          top: 0;
        }

        a {
          width: 90%;
        }

        h3 {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width:$container-breakpoints-xl) {
    /* .the-project {

      &::before {
        content: url("../assets/filigrana-hp.png");

        transform: scale(1);
      }
    } */

    .homepage ::v-deep .slick-slide>div {

      h1,
      p {
        max-width: 80%;
      }
    }

    header {
      height: 94vh;
      background-position-y: 35%;

      h1 {
        font-size: 5rem;
        line-height: 6.5rem;
      }
    }

    .the-project {
      background-color: $primaryColor;
      position: relative;

      &:after {
        display: block;
      }

      >div {
        display: flex;
        flex-direction: row;
      }

      .col-text {
        padding-left: 5rem;
        padding-bottom: 5rem;
      }
    }

    .resources-for-everyone {
      background-color: #EEF2F9;

      h2 {
        text-align: center;
        font-size: 3.75rem;
        line-height: 3.3rem;
        font-weight: 400;
        max-width: 500px;
        margin-bottom: 3rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .box-container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .block-carousel ::v-deep .slick-slide {
    height: auto;
  }

  .block-carousel ::v-deep .slick-track {
    display: flex !important;
  }

  /* @media (min-width: $container-breakpoints-xs ) { } */

  @media (min-width: $container-breakpoints-sm) {
    $call-padding: calc((100vw - $container-max-widths-md) / 2);

    .block-carousel ::v-deep .slick-list {
      position: relative;

      &:before,
      &:after {
        content: '';
        pointer-events: none;
        display: block;
        width: $call-padding;
        position: absolute;
        height: 100%;
        z-index: 10;
        top: 0;
      }
    }
  }

  @media (min-width: $container-breakpoints-md) {
    $call-padding: calc((100vw - $container-max-widths-lg) / 2);

    .block-carousel ::v-deep .slick-track {
      margin-left: calc($call-padding + 15px);
    }

    .block-carousel ::v-deep .slick-list {

      &:before,
      &:after {
        width: $call-padding;
      }
    }

    // .block-carousel ::v-deep .slick-slide:first-child {
    //   width: 40vw;
    // }

    // .block-carousel ::v-deep .slick-slide {
    //   width: 25vw;
    // }

    // .block-carousel ::v-deep .slick-slide:first-child {
    //   width: 50vw;
    // }

    // .block-carousel ::v-deep .slick-slide {
    //   width: 25vw;
    // }


    .block-carousel ::v-deep .slick-list {
      &:before {

        background: linear-gradient(to right, $lightGray 20%, transparent);
        left: 0;
      }

      &:after {

        background: linear-gradient(to left, $lightGray 20%, transparent);
        min-width: 100px;
        right: 0;
      }
    }
  }

  @media (min-width: $container-breakpoints-lg) {
    $call-padding: calc((100vw - $container-max-widths-xl) / 2);

    .block-carousel ::v-deep .slick-track {
      margin-left: calc($call-padding + 80px);
    }

    .block-carousel ::v-deep .slick-list {

      &:before,
      &:after {
        width: $call-padding;
      }
    }

    .homepage .block-carousel ::v-deep .slick-slide {
      margin-right: 1rem;
    }

    .homepage .block-carousel ::v-deep .slick-prev::before {
      left: -50px;
    }

    .homepage .block-carousel ::v-deep .slick-next::before {
      left: 50px;
    }

    .block-carousel ::v-deep .slick-list {
      &:before {
        opacity: 0.5;
      }

      &:after {
        opacity: 0.5;
      }
    }

  }

  @media (min-width: $container-breakpoints-xl) {
    $call-padding: calc((100vw - $container-max-widths-xl) / 2);

    .block-carousel ::v-deep .slick-track {
      margin-left: calc($call-padding + 40px);
    }

    .block-carousel ::v-deep .slick-list {

      &:before,
      &:after {
        width: $call-padding;
      }
    }

    .block-carousel ::v-deep .slick-slide:first-child {
      width: 550px;
      min-width: 550px;
    }

    .block-carousel ::v-deep .slick-slide {
      width: 300px;
      max-width: 300px;
    }


  }

  @media (min-width: $container-breakpoints-xxl) {
    .block-carousel ::v-deep .slick-slide {
      width: 360px;
      max-width: 360px;
    }



    $call-padding: calc((100vw - $container-max-widths-xxl) / 2);

    .block-carousel ::v-deep .slick-track {
      margin-left: calc($call-padding + 40px);
    }

    .block-carousel ::v-deep .slick-list {

      &:before,
      &:after {
        width: $call-padding;
      }
    }

    .block-carousel ::v-deep .slick-slide:first-child:has(div .calltoaction) {
      width: 35vw;
    }

    .block-carousel ::v-deep .slick-slide {
      width: 17vw;
    }
  }
</style>
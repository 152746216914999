<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">
    
    <HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container">

      <div class="page-body blue-title">
        <h3>Only logged users can see this profile.</h3>
        <p>The member you are looking for selected a limited visiblity level on their profile, or they have not completed the registration process yet.</p>
      </div>

    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'PrivateProfile',
  components: {
    HeaderIntroImage
  },
  data() {
    return {
      pageData: {
        attributes: {
          title: "Private Profile"
        }
      },
      currentPageID: ""
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
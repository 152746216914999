<template>
    <div class="filters-block">
        <div @click="collapse" :class="['icon-right read-more filters--title', !collapseFilters ? 'upside' : '']">
            <p class="icon-left filters label">FILTERS</p>
        </div>
        
        <div v-if="!this.collapseFilters" class="inner">
            <slot></slot>
            <div class="col2">
                <div class="buttons">
                    <button class="btn light slim" @click="reset"><span class="icon-right reset">RESET</span></button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'filters-block',
    data: () => {
        return {
            collapseFilters: true
        }
    },
    components: {},
    props: {},
    computed: {},
    methods: {
        reset() {
            this.$emit('reset')
        },
        collapse() {
            this.collapseFilters = !this.collapseFilters
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

input { 
    width: 100%; 
    height: calc(100% - 1em);
    margin: .5em 0;
}

.read-more:hover { cursor: pointer; }

//.filters-block { margin-top: 3rem; }

.inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    margin: 1rem 0 2rem 0;

    .col1 {
        display: grid;
        grid-template-columns: 1fr;
        gap: .5em;
    }

    .col2 { 
        .buttons {
            button {
                margin: 0 auto;
            }
        }
    }
}

@media (min-width: 768px) {
    .inner {
        grid-template-columns: repeat(4, 1fr);
        .col1 {
            grid-column: span 3;
            .row2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1em;
            }
        }
        .col2 {
            grid-column: span 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media (min-width: 1500px) {
    .inner {
        .col1 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            .row2 { gap: 1rem; }
        }
    }
}

</style>
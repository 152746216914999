<template>
  <div class="page-layout details">

    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container">
        <br>
        <p>You registered succesfully to the QTEdu project.</p>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'thank-you-for-registration',
  components: {
    HeaderIntroImage
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
            title: "Thank you for registration"
        }
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {},
  mounted() {},
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
</style>
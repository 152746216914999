<template>
  <div class="page-layout">

    <HeaderIntroImage underlined central :categoryData="categoryData" :headerMetadata="pageData">
      <!-- <br>
      <p class="teaser" v-if="pageSubtitle!=''">{{ this.pageSubtitle }}</p>
      <br> -->
      <button class="slim btn" @click="goto()"><span>Submit</span></button>
    </HeaderIntroImage>

    <div class="container">
      <br><br>
      <div class="list-wrapper">

        <FiltersAside @reset="reset">

          <button :class="['filters--btn', availableOnline ? 'active' : '']" @click="toggleAvailableOnline"><span>Available online</span></button>

          <select name="topic" id="" class="filters--dropdown" @input="addType">
            <option value="all">Filter by Category</option>
            <option v-for="(l, ind) in typeList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>

          <div class="active-filter icon-right close" v-for="(l, ind) in typeFilter" :key="'type'+ind" @click="removeType(l)">{{ l }}</div>
          
          <select name="country" id="" class="filters--dropdown" @input="addLang">
            <option value="all">Filter by Language</option>
            <option v-for="(l, ind) in langList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          <div class="active-filter icon-right close" v-for="(l, ind) in langFilter" :key="'lang'+ind" @click="removeLang(l)">{{ l }}</div>

          <select name="country" id="" class="filters--dropdown" @input="addTarget">
            <option value="all">Filter by Target</option>
            <option v-for="(l, ind) in targetList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>

          <div class="active-filter icon-right close" v-for="(l, ind) in targetFilter" :key="'targ'+ind" @click="removeTarget(l)">{{ l }}</div>


          <select name="country" id="" class="filters--dropdown" @input="addLocation">
            <option value="all">Filter by Location</option>
            <option v-for="(l, ind) in locationList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          <div class="active-filter icon-right close" v-for="(l, ind) in locationFilter" :key="'location'+ind" @click="removeLocation(l)">{{ l }}</div>
       
<!-- 
          <select name="isAvailable" class="filters--dropdown" @input="addAvailable">
            <option value="all">Filter by Available Online</option>
            <option v-for="(l, ind) in availableList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          
          <div class="active-filter icon-right close" v-for="(l, ind) in availableFilter" :key="'targ'+ind" @click="removeAvailable(l)">{{ l }}</div>

         -->

        </FiltersAside>

        <div v-if="this.loading">
          <br>
          <h3>Loading data ...</h3>
          <br>
        </div>

        <ArticlesList nodetype="generic--list" v-else>
          <div class="no-data" v-if="filteredMaterials.length==0">
            <h3>No Data</h3>
          </div>
          <div v-else>
            <h4 class="nitems"><span>{{ filteredMaterials.length }}</span> items</h4>
          </div>

          <MaterialCard v-for="(mat, index) in this.filteredMaterials" :key="'material'+index" addclass="material" 
            :articleData="mat" :routesData="routesData" :cat="categoryData"/>
        </ArticlesList>
      </div>
    </div>

  </div>
</template>

<script>
import FiltersAside from '../components/layout-blocks/FiltersAside.vue'
import MaterialCard from '../components/layout-blocks/MaterialCard.vue'
import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'material-and-tools',
  components: {
    HeaderIntroImage,
    FiltersAside,
    MaterialCard,
    ArticlesList
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Material and Tools"
        }
      },
      materialData: [],
      typeFilter: [],
      langFilter: [],
      targetFilter: [],
      availableOnline: false,
      availableFilter: [],
      locationFilter: [],
      loading: true
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    category() {
      return this.$route.params.title
    },
    routesData() {
      return this.$store.state.routesData
    },
    langList() {
      var lang = []

      if(this.materialData) {
        this.materialData.forEach(m =>{
          if(m.field_material_language)
            m.field_material_language.forEach(l => {
              if(l && !lang.includes(l.attributes.name))
                lang.push(l.attributes.name)
            })
        })
      }

      return lang.sort((a,b) => { return a>b ? 1 : -1 })
    },
    typeList() {
      var type = []

      if(this.materialData) {
        this.materialData.forEach(m =>{
          if(m.field_material_typology.length)
            m.field_material_typology.forEach(l => {
              if(l && !type.includes(l.attributes.name))
                type.push(l.attributes.name)
            })
        })
      }

      return type.sort((a,b) => { return a>b ? 1 : -1 })
    },
    targetList() {
      var tg = []

      if(this.materialData) {
        this.materialData.forEach(m =>{
          if(m.field_school_target)
            m.field_school_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
          if(m.field_higher_education_target)
            m.field_higher_education_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
          if(m.field_life_long_learning_target)
            m.field_life_long_learning_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
          if(m.field_outreach_target)
            m.field_outreach_target.forEach(l => {
              if(l && !tg.includes(l.attributes.name))
                tg.push(l.attributes.name)
            })
        })
      }

      return tg.filter(t => t!='Other (specify)').sort((a,b) => { return a>b ? 1 : -1 })
    },
    availableList() {
      let ava = ["Available Online"]
      return ava
    },
    locationList() {
      var loc = []

      if(this.materialData) {
        this.materialData.forEach(m =>{
          if(m.field_material_organization)
            m.field_material_organization.forEach(l => {
              if(l && l.attributes.field_org_mat_prg_country) 
                if(!loc.includes( this.$store.getters.getCountry(l.attributes.field_org_mat_prg_country.country)))
                  loc.push( this.$store.getters.getCountry(l.attributes.field_org_mat_prg_country.country))
            })
        })
      }

      return loc.sort((a,b) => { return a>b ? 1 : -1 })
    },
    filteredMaterials() {
      var mats = []

      if(this.materialData) {

        mats = this.materialData


        if(this.availableOnline)
          mats = mats.filter(p => p.attributes.field_available_online)

        if(this.typeFilter.length) {
          mats = mats.filter(m => {
            var found = false
            m.field_material_typology.forEach(t => {
              if(this.typeFilter.includes(t.attributes.name))
                found = true
            })
            return found
          })
        }

        if(this.langFilter.length) {
          mats = mats.filter(m => {
            var found = false
            m.field_material_language.forEach(t => {
              if(this.langFilter.includes(t.attributes.name))
                found = true
            })
            return found
          })
        }

        if(this.locationFilter.length) {
          mats = mats.filter(m => {
            var found = false
            m.field_material_organization.forEach(t => {
              if(t.attributes.field_org_mat_prg_country)
                if(this.locationFilter.includes( this.$store.getters.getCountry(t.attributes.field_org_mat_prg_country.country) ))
                  found = true
            })
            return found
          })
        }

        if(this.targetFilter.length) {
          mats = mats.filter(m => {

            var found = false
            m.field_school_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            m.field_higher_education_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            m.field_life_long_learning_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })
            m.field_outreach_target.forEach(t => {
              if(this.targetFilter.includes(t.attributes.name))
                found = true
            })

            return found
          })
        }

        if(this.availableFilter.length) {
          mats = mats.filter(m => {

            var found = false

           //console.log('m',m);

            if (m.attributes.field_available_online != null){
              found = true
            }

            return found

          })
        }

      }

      return mats
      
    },
    categoryData() {
      var cd = {
        nid: 0,
        name: "",
        pageid: ""
      }

      switch(this.category) {
        case 'primary-and-secondary-school':
          cd.name = "Primary and Secondary School"
          cd.pageid = "1af89bf7-177d-47dd-8565-37115229c72a"
          cd.nid = 14
          break;
        case 'higher-education':
          cd.name = "Higher Education"
          cd.pageid = "5d95cfeb-b3b2-4f43-93f8-89edec75434d"
          cd.nid = 15
          break;
        case 'lifelong-learning-and-retraining':
          cd.name = "Lifelong Learning and (re)training"
          cd.pageid = "1aeb13a0-a101-4d43-bda7-70c6cb3bac23"
          cd.nid = 16
          break;
        case 'outreach':
          cd.name = "Outreach"
          cd.pageid = "3dd03817-6483-4dff-8303-d4c22a08bb9f"
          cd.nid = 17
          break;
        default:
          cd.name = ""
          cd.nid = 0
          cd.pageid = ""
          break;
      }

      return cd
    },
   
  },
  methods: {
    toggleAvailableOnline() {
      this.availableOnline = !this.availableOnline
    },
    addLang(event) {
      if(!this.langFilter.includes(event.target.value))
        this.langFilter.push(event.target.value)

    event.target.value = 'all'
    },
    removeLang(l) {
      var ind = this.langFilter.indexOf(l)
      this.langFilter.splice(ind, 1)
    },
    addType(event) {
      if(!this.typeFilter.includes(event.target.value))
        this.typeFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeType(l) {
      var ind = this.typeFilter.indexOf(l)
      this.typeFilter.splice(ind, 1)
    },
    addTarget(event) {
      if(!this.targetFilter.includes(event.target.value))
        this.targetFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeTarget(l) {
      var ind = this.targetFilter.indexOf(l)
      this.targetFilter.splice(ind, 1)
    },
    addAvailable(event) {

      if(!this.availableFilter.includes(event.target.value))
        this.availableFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeAvailable(l) {
      var ind = this.availableFilter.indexOf(l)
      this.availableFilter.splice(ind, 1)
    },
    addLocation(event) {
      if(!this.locationFilter.includes(event.target.value))
        this.locationFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeLocation(l) {
      var ind = this.locationFilter.indexOf(l)
      this.locationFilter.splice(ind, 1)
    },
    reset() {
      this.availableOnline = false
      this.typeFilter = []
      this.langFilter = []
      this.locationFilter = []
      this.targetFilter = []
      this.availableFilter = []
    },
    goto() {
      this.$router.push('/get-involved')
    },
    async findCT() {
      this.loading = true 

      await fetchSinglePage(this.categoryData.pageid, {})
      .then(res => this.pageData = res)

      await fetchNodes('material', {
        include: ['field_level', 'field_material_language', 'field_material_organization', 
          'field_material_typology', 'field_material_topic',
          'field_school_target', 'field_higher_education_target', 'field_life_long_learning_target', 'field_outreach_target']
      })
      .then(res => {
        this.materialData = res.filter(p => {
          var found = false
          p.field_level.forEach(f => {
            //console.log(f.attributes.name)
            if(f.attributes.name.toLowerCase() == this.categoryData.name.toLowerCase())
              found = true
          })
          return found
        })
        //console.log('materials', this.materialData)
      })

      this.loading = false
    }
  },
  async mounted() {
    this.loading = true

    this.reset()

    if(this.categoryData.name=="")
      this.$router.push('/not-found')

    await this.findCT()
  },
  watch: {
    async category() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.nitems {
  margin: 0 0 0 auto;
  span { font-weight: 700; }
}

.active-filter {
  padding: .25em;
  font-size: 14px;
  padding-right: 1.5em;
  color: $middleBlue;
  &:before { padding-right: .5em; }
  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
}
</style>
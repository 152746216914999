import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueMeta from 'vue-meta'
import { router } from './router/index'
import VueGtag from "vue-gtag";

router().then(router => {
  Vue.use(VueMeta)

  router.afterEach((to) => {
    
    //Analitycs
    Vue.use(VueGtag, {
      config: { id: process.env.VUE_APP_ANALYTICS_CODE }
    }, router);


    //language come impostato nello STORE
    var lfs = store.state.currentLang

    //language come selezionato dall'URL PATH
    var lfp = to.fullPath.substring(1, 4)

    //controlla i primi due caratteri dell'url
    //se sono contenuti nell'array delle lingue disponibili + sono seguiti da un '/' o da nessun carattere
    if(store.state.languages.includes(lfp.substring(0, 2)) && (lfp[2]=='/' || lfp[2]==undefined)) {

      //allora è stata selezionata una nuova lingua direttamente dall'url
      //impostazione diretta della lingua (es: quando si arriva da un link condiviso)
      //console.log('SET CURRENT LANGUAGE', lfp.substring(0, 2))
      store.commit('SET_CURRENT_LANG', lfp.substring(0, 2))

    } else {
      //altrimenti significa che i caratteri seguenti il dominio definiscono direttamente una route
      //console.log('SET DEFAULT LANGUAGE', lfs)
      store.commit('SET_CURRENT_LANG', lfs)
    }
  })

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})
<template>
    <keep-alive v-if="responsiveStyles.styles">

        <!--<keep-alive v-if="sourceToRender.length===1">
            <img :class="[imgclass]" 
                :src="responsiveStyles.image_original" 
                :alt="responsiveStyles.alt"
                :width="responsiveStyles.width" :height="responsiveStyles.height">
        </keep-alive>-->

        <!--<keep-alive>-->
            <keep-alive v-if="sourceToRender.length===0">
                <img :class="[imgclass]" 
                    :src="responsiveStyles.image_original"
                    :alt="responsiveStyles.alt"
                    :width="responsiveStyles.width" :height="responsiveStyles.height"
                    :importance="priority">
            </keep-alive>

            <keep-alive v-else>
                <picture :class="[imgclass]">
                    <!-- un SOURCE tag per ogni style specificato da utilizzare -->
                    <source v-for="(el, i) in this.sourceToRender.reverse()" :key="'source'+i"
                        :srcset="responsiveStyles.styles['_x1'][breakstyles[el]].url + ' 1x, ' + 
                                responsiveStyles.styles['_x2'][breakstyles[el]].url + ' 2x'"
                        :media="'(min-width: ' + el + 'px)'"
                        :alt="responsiveStyles.alt"
                        :importance="priority"
                    >

                    <!-- una sola IMG per fallback, con stile default uguale a quello per la viewport più bassa -->
                    <img class="dynamic"
                        :src="responsiveStyles.styles['_x1'][breakstyles[sourceToRender[0]]].url + ' 1x, ' + 
                            responsiveStyles.styles['_x2'][breakstyles[sourceToRender[0]]].url + ' 2x'"
                        :alt="responsiveStyles.alt"
                        :width="responsiveStyles.width" :height="responsiveStyles.height"
                        :importance="priority"
                    >
                </picture>
            </keep-alive>
        <!--</keep-alive>-->

    </keep-alive>
</template>

<script>
export default {
    name: 'image-item',
    data: () => {
        return {
            responsiveStyles: {}
        }
    },
    props: {
        imagedata: Object,      //dati completi immagine per alt e dimensioni iniziali 
        breakstyles: Object,    //(vengono considerati solo i breakpoint specificati) {'breakpoint' : 'style name'}
        imgclass: String,       //stile specifico per immagine fullwidth o altri stili
        fieldname: {            //nome del campo in cui è contenuta l'immagine
            type: String,               //da specificare come nel caso dell'header, o per immagini inserite nel contenuto body
            default: 'field_multipla'   //di default il campo è quello apposito per le immagini
        },
        resultindex: {          //indice nell'array delle immagini di quella che vogliamo utilizzare
            type: Number,
            default: 0          //se vogliamo usare l'unica presente non servirà specificare
        },
        priority: {
            type: String,
            default: "low"
        }
    },
    computed: {
        //calcola quali sono i breakpoints da caricare
        sourceToRender() {
            if(this.breakstyles)
                return Object.keys(this.breakstyles)
            else 
                return []
        }
    },
    created(){
        //se esiste l'immagine collegata
        if(this.imagedata.attributes) {

            fetch(process.env.VUE_APP_ENDPOINT + '/images-url/' + this.imagedata.attributes.drupal_internal__nid + '/' + this.fieldname )
            .then(res => res.json())
            .then(json => {
                if(json.images)
                    this.responsiveStyles = json.images[this.resultindex]
                /* 
                //DEBUG
               //console.log('breakstyles', this.breakstyles)
               //console.log('responsiveStyles', this.responsiveStyles)
               //console.log('sourceToRender', this.sourceToRender)
                */
            })
        }
    },
    watch: {
        imagedata() {
            fetch(process.env.VUE_APP_ENDPOINT + '/images-url/' + this.imagedata.attributes.drupal_internal__nid + '/' + this.fieldname )
            .then(res => res.json())
            .then(json => {
                this.responsiveStyles = json.images[this.resultindex]
            })
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
}

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}
</style>
<template>
    <article class="event event-min">
        <router-link :to="detailLink" class="tile plain">
            <WhiteCard flipped>
                <div class="col1"> 
                    
                    <h4 class="article--title gray">{{ articleData.attributes.title }}</h4>
                    
                </div>
                <div class="col2">
                    <div class="article--meta date">
                        <p v-html="completeDate"></p>
                        <div class="article--tag blue icon-left location" v-if="articleData.attributes.field_event_location_address">{{ articleData.attributes.field_event_location_address }}</div>
                    </div>
                </div>
            </WhiteCard>
        </router-link>
    </article>
</template>

<script>
import WhiteCard from '../atomic-elements/WhiteCard.vue'
export default {
    name: 'event-card-min',
    components:{
        //ImageItem
        WhiteCard
    },
    data: () => {
        return {}
    },
    props: {
        articleData: Object,
        cat: Object, 
        routesData: Array
    },
    computed: {
        startDate() {
            var year = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { year: 'numeric'}) 
            var month = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { month: 'short'}) 
            var day = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { day: 'numeric'}) 

            return '<span>' + day + ' ' + month+ '</span> ' + year
        },
        endDate() {
            var year = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { year: 'numeric'}) 
            var month = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { month: 'short'}) 
            var day = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { day: 'numeric'}) 

            return '<span>' + day + ' ' + month+ '</span> ' + year
        },
        completeDate() {
            return this.startDate + ' - ' + this.endDate
        },
        detailLink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid)
            if(link.length>0)
                path = link[0].path
            else path = '/not-found'

            return path
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.event-min {
    .col1 {
        border-bottom: 2px darken($hoverGray, 10%) dashed;
        .article--title { font-size: 18px; }
    }
    .col2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .date {
            p { font-size: 11px; }
        }
    }

    .tile,
    .tile .white-card {
        min-height: 250px;
        width: 100%;
        height: 100%;
    }

    &:hover {
        .white-card {
            background-color: $hoverGray;
            box-shadow: none;
        }
        .col1 { border-bottom: 2px darken($hoverGray, 20%) dashed; }
    }
}
</style>
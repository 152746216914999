<template>
    <header :class="['header-intro', 'dark-bg', backstep ? 'backstep' : '']">
        <div :class="['container', 
            central ? 'central' : '',
            underlined ? 'underlined' : '']" 
            v-if="this.headerMetadata && this.headerMetadata.attributes">
             
             <h5 v-if="target" class="working-group--icon">
                <span :class="['icon-left', targetclass]"></span>
            </h5>

            <h1> {{ this.headerMetadata.attributes.title }} </h1>

            <h5 v-if="this.headerMetadata.attributes.field_banner_claim && categoryData" class="header--tag">
                <span :class="['icon-left', targetclass]">{{ this.headerMetadata.attributes.field_banner_claim }}</span>
            </h5>

            <!-- <h5 v-if="!this.headerMetadata.attributes.field_banner_claim && this.categoryData && !target" class="header--tag">
                <span :class="['icon-left', targetclass]">{{ this.categoryData.name }}</span>
            </h5> -->

            <!-- <p class="teaser" v-if="this.headerMetadata.attributes.field_banner_claim">
                {{ this.headerMetadata.attributes.field_banner_claim }}
            </p> -->

            <p class="teaser" v-if="this.headerMetadata.attributes.field_page_main_content && categoryData" 
                v-html="this.headerMetadata.attributes.field_page_main_content.value">
            </p>

            <!-- space for specials custom elements and buttons -->
            <slot></slot>

        </div>
        <img class="banner-image" :src="bannerImage" alt="banner image">
    </header>
</template>

<script>
export default {
    name: 'header-intro-page',
    props: {
        headerMetadata: Object,
        backstep: Boolean,
        target: Boolean,
        categoryData: Object,
        central: Boolean,
        underlined: Boolean
    },
    computed:{
        bannerImage(){
            return require("../../assets/banner-page.png")
        },
        targetclass() {
            //console.log(this.categoryData)
            if(this.categoryData.nid)
                return 'target' + this.categoryData.nid
            else 
                return ''
        }
    },
    mounted(){
        //console.log('header', this.headerMetadata);
    }
}
</script>

<style lang="scss">
@import '../../styles/variables.scss';

.header-intro .container .teaser p { width: 100%; }

.header-intro {
    min-height: 40vh;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:after {
        background: linear-gradient(#07115200, $primaryColor);
        width: 100%;
        height: 430px;
        bottom: 0;
        left: 0;
        content: "";
        position: absolute;
        z-index: 0;
    }

    h1 {
        position: relative;
        width: fit-content;
    }

    /*.header--tag {
        margin-top: -.5em;
        margin-bottom: 1.5em;
    }*/

    .container {
        position: relative;
        z-index: 1;

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        p {
            width: 100%;
            line-height: 2em;
        }

        &.underlined h1 {
            &:after {
                background: url('../../assets/underline_title_lightblue.svg');
                width: 80%;
                height: 1rem;
                bottom: -1.5rem;
                background-repeat: no-repeat;
                left: 20px;
                content: "";
                position: absolute;
                z-index: 0;
            }
            &:before {
                background: url('../../assets/icon_rombo_shade.svg');
                width: 1rem;
                height: 1rem;
                bottom: -1.5rem;
                left: 10px;
                content: "";
                position: absolute;
                z-index: 1;
            }
        }

        &.central {
            align-items: center;
            text-align: center;
            h1 {
                //width: 100%;
                text-align: center;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
                &:before {
                    left: calc(10% - 10px);
                }
            }
        }
    }
}

.header-intro {
    position: relative;
    //border: 2px solid rgb(22, 0, 146);
    background-color: $primaryColor;
    padding: calc(3rem + $heightMobile) 0rem 3rem 0rem;

    &.backstep { padding-bottom: calc(2rem + $stepHeight); }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        //color: rgb(0, 13, 195);
        content:"header intro"
    }
}

.banner-image {
    position: absolute;
    top: 0;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
}

@media (min-width: 992px) {
    .header-intro {
        padding-top: calc(2rem + $heightDesktop);
        min-height: 44vh;
        
        .container p {
            width: 70%;
        }
    }
}

</style>
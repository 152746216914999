<template>
    <article class="news" v-if="articleData && articleData.attributes">
        <router-link :to="detailLink" class="tile plain">
            <div class="article--thumbnail">
                <ImageItem v-if="articleData.field_image" :imagedata="articleData" 
                    fieldname="field_image" 
                    :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                </ImageItem>
                <picture v-else>
                    <img :src="newsimg" alt="news cover placeholder">
                </picture>
            </div>

            <div class="article--content">
                <!--<div class="inner">-->
                    <div v-if="cat.nid!=0" :class="['article--tag', 'icon-left', 'target'+cat.nid]">
                        {{ cat.name }}
                    </div>

                    <div class="article--meta date icon-right arrow">
                        <p v-html="date"></p>
                    </div>

                    <h4 class="article--title">
                        {{ articleData.attributes.title }}
                    </h4>
                    
                    <div class="article--teaser teaser" v-if="articleData.attributes.field_description">
                        <p v-html="articleData.attributes.field_description.value">
                        </p>
                    </div>
                <!--</div>-->
            </div>
            
        </router-link>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem.vue'
export default {
    name: 'article-card',
    components: {
        ImageItem
    },
    data: () => {
        return { }
    },
    props: {
        articleData: Object,
        imageData: Object,
        routesData: Array,
        backimg: Object,
        cat: Object,
        fieldname: {        
            default: 'field_banner_top',    
            type: String                    
        },
    },
    computed: {
        date() {
            var year = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { year: 'numeric'}) 
            var month = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { month: 'short'}) 
            var day = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { day: 'numeric'}) 

            return '<span>' + day + ' ' + month + '</span> ' + year
        },
        detailLink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid)
            if(link.length>0)
                path = link[0].path
            else path = '/not-found'

            return path
        },
        newsimg() {
            return require('../../assets/news-placeholder.jpg')
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="page-layout">

    <HeaderIntroImage underlined central :headerMetadata="pageData" :categoryData="categoryData">
      <!-- <br v-if="!pageData.attributes.field_banner_claim">
      <button class="slim btn"><span>Submit</span></button> -->
    </HeaderIntroImage>

    <div class="container" v-if="this.loading">
      <br>
      <br>
      <h3>Loading data ...</h3>
      <br>
      <br>
    </div>

    <div class="container" v-if="this.projectsData.length && !this.loading">
      <br><br>
      <div class="list-wrapper">

        <ArticlesList nodetype="generic--list">

          <div class="no-data" v-if="filteredProjects.length==0">
            <h3>No Data</h3>
          </div>
          <div v-else>
            <h4 class="nitems"><span>{{ filteredProjects.length }}</span> items</h4>
          </div>

          <ProjectCard v-for="(p, index) in filteredProjects" :key="'project'+index"
            addclass="project"
            :articleData="p"
            :routesData="routesData"
            :cat="categoryData"
          />
        </ArticlesList>
      </div>
    </div>

  </div>
</template>

<script>
import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import ProjectCard from '../components/layout-blocks/ProjectCard.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'projects',
  components: {
    HeaderIntroImage,
    ProjectCard,
    ArticlesList,
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Pilot Projects"
        }
      },
      /* currentPageID: "3dd03817-6483-4dff-8303-d4c22a08bb9f", */
      projectsData: [],
      loading: true
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    category() {
      return this.$route.params.title
    },
    routesData() {
      return this.$store.state.routesData
    },
    categoryData() {
      var cd = {
        nid: 0,
        name: "",
        pageid: ""
      }

      switch(this.category) {
        case 'primary-and-secondary-school':
          cd.name = "Schools"
          cd.pageid = "e64e810b-2803-47d3-bbe3-1ac46997c75e"
          cd.nid = 14
          break;
        case 'higher-education':
          cd.name = "Higher education"
          cd.pageid = "330dcf22-ce0c-4dd2-84f5-b63a7d1675a2"
          cd.nid = 15
          break;
        case 'industry':
          cd.name = "Industry"
          cd.pageid = "26aeb6ba-b4f3-4005-bf4a-2700b0be0854"
          cd.nid = 17
          break;
        case 'outreach':
          cd.name = "General public"
          cd.pageid = "169473b6-75a6-4162-a087-7fac68579f91"
          cd.nid = 16
          break;
        default:
          cd.name = ""
          cd.nid = 0
          cd.pageid = ""
          break;
      }

      return cd
    },
    filteredProjects() {
      var fp = this.projectsData.filter(pj => {
        //console.log(pj.attributes.field_project_target)

        if(pj.attributes.field_project_target.length) 
          return pj.attributes.field_project_target.includes(this.categoryData.name)

        else
          return false
      })

      //console.log(fp)

      return fp
    }
  },
  methods: {
    async findCT() {
      this.loading = true 

      if(this.categoryData.name=="")
        this.$router.push('/not-found')

      await fetchSinglePage(this.categoryData.pageid, {
        include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
      })
      .then(res => this.pageData = res )

      /*//console.log('this.pageData',this.pageData); */

      await fetchNodes('pilot_project', {
        include: ['field_ref_working_group', 'field_pilot_project_partner', 'field_pilot_project_coordinator', 'field_logo']
      })
      .then(res => {
        this.projectsData = res
        //console.log('projects', this.projectsData)
      })

      this.loading = false
    }
  },
  mounted() {
    this.loading = true

    if(this.categoryData.name=="")
      this.$router.push('/not-found')

    this.findCT()  
  },
  watch: {
    category() {
      this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
.nitems {
  margin: 0 0 0 auto;
  span { font-weight: 700; }
}
</style>
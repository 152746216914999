<template>
    <div :class="['white-card', flipped ? 'flipped': '']">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'white-card',
    data: () => {
        return {}
    },
    props: {
        flipped: Boolean
    },
    methods: {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.white-card {
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px .5em rgba(#000, .2);
    padding: 1.2em 1.2em 1.2em 2em;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: .5em;
        height: 100%;
        content: '';
        background: $vgradient;
    }

    &.flipped {
        border-radius: 0;
        padding: 1.2em 2em 1.2em 1.2em;
        &:before {
            position: absolute;
            bottom: 0;
            top: unset;
            left: 0;
            width: 100%;
            height: .5em;
            content: '';
            background: $gradient;
        }
    }

}

</style>
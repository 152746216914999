<template>
  <div class="page-layout evaluation-tools">

    <HeaderIntroImage underlined central :categoryData="categoryData" :headerMetadata="pageData">
      <!-- <br>
      <br> -->
      <button class="slim btn" @click="goto()"><span>Submit</span></button>
    </HeaderIntroImage>

    <div class="container">
      <br><br>
      
        <p class="description">
          Our collection includes tools to evaluate education programs and activities about Quantum Technologies within school and higher education, workforce (re)training, and citizens outreach. We gather assessments, evaluations, self-assessments, pre-post tests, and other tools for evaluating education programs, courses, modules, training programs, and outreach activities. The scientific validation of the tools is indicated.
          We are collaborating with PhysPort and using their assessment repository to collect the tools, please visit <a href="https://www.physport.org/assessments/?Q=&Subjects=90&fCI=&Ordering=Relevance">PhysPort Assessment on Modern/Quantum</a> to see the collection.
        </p>

        <p class="description">
          If you have a tool to share please contact us at <a href="mailto:into@qtedu.eu">into@qtedu.eu</a>
        </p>

      <!-- <div class="list-wrapper">
        <FiltersAside @reset="reset()">
          <button :class="['filters--btn', typeFilter.includes('Multiple-choice') ? 'active' : '']" 
            @click="toggleFilter('Multiple-choice')"><span>Multiple-choice</span></button>
          <button :class="['filters--btn', typeFilter.includes('Short Answers') ? 'active' : '']" 
            @click="toggleFilter('Short Answers')"><span>Short Answers</span></button>

          <select name="country" id="" class="filters--dropdown" @input="addTarget">
            <option value="all">Filter by Target</option>
            <option v-for="(l, ind) in targetList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          <div class="active-filter icon-right close" v-for="(l, ind) in targetFilter" :key="'targ'+ind" @click="removeTarget(l)">{{ l }}</div>
          <select name="country" id="" class="filters--dropdown" @input="addFocus">
            <option value="all">Filter by Focus</option>
            <option v-for="(l, ind) in focusList" :key="'lang'+ind" :value="l">{{ l }}</option>
          </select>
          <div class="active-filter icon-right close" v-for="(l, ind) in focusFilter" :key="'focus'+ind" @click="removeFocus(l)">{{ l }}</div>
        </FiltersAside>

        <div v-if="this.loading">
          <br>
          <h3>Loading data ...</h3>
          <br>
        </div>

        <ArticlesList nodetype="generic--list" v-else>
          <div class="no-data" v-if="filteredEvTools.length==0">
            <h3>No Data</h3>
          </div>

          <EvaluationCard v-for="(tool, index) in this.filteredEvTools" :key="'tool'+index" 
            :articleData="tool" :routesData="routesData" :cat="categoryData"/>
        </ArticlesList>
      </div> -->
    </div>

  </div>
</template>

<script>
//import FiltersAside from '../components/layout-blocks/FiltersAside.vue'
//import EvaluationCard from '../components/layout-blocks/EvaluationCard.vue'
//import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage, /*fetchRoutes*/ } from '../libs/drupalClient'
export default {
  name: 'evaluation-tools',
  components: {
    HeaderIntroImage,
    // FiltersAside,
    // EvaluationCard,
    // ArticlesList
  },
  props: {},
  data: () => {
    return {
      pageData: null,
      currentPageID: "c9211554-aecd-48ef-b8e3-51926126aced",
      evalData: [],
      loading: true,
      focusFilter: [],
      targetFilter: [],
      typeFilter: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    category() {
      return this.$route.params.title
    },
    routesData() {
      return this.$store.state.routesData
    },
    targetList() {
      var tg = []

      if(this.evalData) {
        this.evalData.forEach(m =>{
          if(m.attributes.field_evaluation_target)
            m.attributes.field_evaluation_target.forEach(l => {
              if(l && !tg.includes(l))
                tg.push(l)
            })
        })
      }

      return tg.sort((a,b) => { return a>b ? 1 : -1 })
    },
    focusList() {
      var tg = []

      if(this.evalData) {
        this.evalData.forEach(m =>{
          if(m.attributes.field_assessment_focus && !tg.includes(this.assessment(m.attributes.field_assessment_focus)))
            tg.push(this.assessment(m.attributes.field_assessment_focus))
        })
      }

      return tg.sort((a,b) => { return a>b ? 1 : -1 })
    },
    filteredEvTools() {
      var evts = []

      if(this.evalData) {
        evts = this.evalData

        if(this.typeFilter.length) {
          evts = evts.filter(m => {
            var found = false
            if(this.typeFilter.includes(this.format(m.attributes.field_format)))
              found = true
            return found
          })
        }

        if(this.targetFilter.length) {
          evts = evts.filter(m => {
            var found = false
            m.attributes.field_evaluation_target.forEach(t => {
              if(this.targetFilter.includes(t))
                found = true
            })
            return found
          })
        }

        if(this.focusFilter.length) {
          evts = evts.filter(m => {
            var found = false
            if(this.focusFilter.includes(this.assessment(m.attributes.field_assessment_focus)))
              found = true
            return found
          })
        }
      }

      return evts
    },
    categoryData() {
      var cd = {
        nid: 0,
        name: "",
        pageid: ""
      }

      switch(this.category) {
        case 'primary-and-secondary-school':
          cd.name = "Primary and Secondary School"
          cd.pageid = "aaaf1555-bb02-4b7e-8b22-6f6a72cad123"
          cd.nid = 14
          break;
        case 'higher-education':
          cd.name = "Higher Education"
          cd.pageid = "8db0ed36-1103-456f-b528-a76939fd3723"
          cd.nid = 15
          break;
        case 'lifelong-learning-and-retraining':
          cd.name = "Lifelong Learning and (re)training"
          cd.pageid = "efc73746-2be6-4073-bfac-9ab026b70b44"
          cd.nid = 16
          break;
        case 'outreach':
          cd.name = "Outreach"
          cd.pageid = ""
          cd.nid = 17
          break;
        default:
          cd.name = ""
          cd.nid = 0
          cd.pageid = ""
          break;
      }

      return cd
    }
  },
  methods: {
    toggleFilter(name) {
      if(!this.typeFilter.includes(name))
        this.typeFilter.push(name)
      else 
        this.typeFilter.splice(this.typeFilter.indexOf(name), 1)
    },
    format(f) {
      switch(f) {
        case '1':
          return 'Multiple-choice';
        case '2': 
          return 'Short Answers';
        default:
          return 'Tools';
      }
    },
    assessment(f) {
      switch(f) {
        case '1':
          return 'Content knowledge';
        case '2': 
          return 'Problem-solving';
        case '3': 
          return 'Scientific reasoning';
        case '4': 
          return 'Lab skills';
        case '5': 
          return 'Beliefs';
        case '6':
          return 'Attitudes';
        case '7': 
          return 'Interactive teaching';
        default: 
          return 'Evaluation';
      }
    },
    addTarget(event) {
      if(!this.targetFilter.includes(event.target.value))
        this.targetFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeTarget(l) {
      var ind = this.targetFilter.indexOf(l)
      this.targetFilter.splice(ind, 1)
    },
    addFocus(event) {
      if(!this.focusFilter.includes(event.target.value))
        this.focusFilter.push(event.target.value)

      event.target.value = 'all'
    },
    removeFocus(l) {
      var ind = this.focusFilter.indexOf(l)
      this.focusFilter.splice(ind, 1)
    },
    reset() {
      this.targetFilter = []
      this.typeFilter = []
      this.focusFilter = []
    },
    goto() {
      this.$router.push('/get-involved')
    },
    async findCT() {
      this.loading = true 

      await fetchSinglePage(this.categoryData.pageid, {})
      .then(res => this.pageData = res )

      await fetchNodes('evaluation', {
        include: ['field_level']
      })
      .then(res => {
        this.evalData = res.filter(p => {
          var found = false
          p.field_level.forEach(f => {
            //console.log(f.attributes.name)
            if(f.attributes.name.toLowerCase() == this.categoryData.name.toLowerCase())
              found = true
          })
          return found
        })
        //console.log('evaluations', this.evalData)
      })

      this.loading = false
    }
  },
  async mounted() {
    this.loading = true

    if(this.categoryData.name=="")
      this.$router.push('/not-found')

    await this.findCT()
  },
  watch: {
    async category() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.description {
  max-width: 800px;
  padding: 0rem 0rem 2rem;
  margin: 0px auto;
}

.active-filter {
  padding: .25em;
  font-size: 14px;
  padding-right: 1.5em;
  color: $middleBlue;
  &:before { padding-right: .5em; }
  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
}
</style>
<template>
  <header class="navigation" v-on:scroll="scrollFunction()" :class="{sticky : windowScrolled}">
    <nav class="navbar">
      <div class="container">

        <router-link :to="lang==defaultLang ? '/' : '/'+lang" class="navbar-brand" @click.native="closeMenu">
          <img src="@/assets/QTEdu-logo-colors-white.svg" alt="logo" class="logo logo-desktop" />
        </router-link>

        <div :class="['menu-list inner-container', menuOpen ? 'show' : '']">
          <div class="container">
            <!-- create a list of menu routes -->
            <ul class="navbar-nav">
              <!-- for every top level menu route ... -->
              <li v-for="(item, index) of menuItems" :key="'menu' + index"
                :class="['nav-item', index === submenuOpen ? 'active' : '']" @click="openSubmenu(index)">
                <RecursiveLink :voicenum="(index+1)" :item="item" :level="1" @close-menu="closeMenu()" />
              </li>

              <div class="nav-item">
                <button @click="goto('/get-involved')" class="btn slim"><span>Get involved</span></button>
                <button v-if="!loggedUser" @click="goto('/login')" class="login btn plain slim light"><span
                    class="icon-right profile">Login</span></button>
                <button v-if="loggedUser" @click="logout()" class="login btn plain slim light"><span
                    class="icon-right profile">Logout</span></button>
                <!--<router-link to="/get-involved" class="btn slim plain"><span>Get involved</span></router-link>-->
              </div>

            </ul>
            
               
          </div>
         
          </div>
          
     <div class="search-container">
      <div class="search-btn">
              <a @click="openSearchModal = !openSearchModal " >
              </a>
      </div>
              
          </div>

        <div class="navbar-toggler">
          <label for="check">
            <input type="checkbox" id="check" @click="toggleMenu" />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

      

        <div class="search-modal desktop" v-if="openSearchModal">
          <div>
            <div class="close" @click="openSearchModal = !openSearchModal ">
              <img src="../../assets/icon-close-search.svg" alt="close-search">
            </div>
            <h1>What are you searching?</h1>
            <img class="deco"  src="../../assets/search-deco.svg"  width="247" alt="search-deco">
            <form @submit.prevent="searchData">
              <div class="container-input">
 
              <input autofocus type="search" id="search" name="search" placeholder="Free search..." v-model="searchField"><br><br>
              </div>

              <button  @click="searchData" class="btn slim">
                Apply  
              </button>

            </form>
          </div>
        </div>


      </div>
    </nav>
  </header>
</template>

<script>
  import RecursiveLink from '../atomic-elements/RecursiveLink.vue'
  export default {
    name: 'the-header',
    components: {
      RecursiveLink
    },
    data() {
      return {
        windowScrolled: false,
        menuOpen: false,
        submenuOpen: null,
        submenuVisible: true,
        menuItems: [],
        searchField:'',
        openSearchModal:false
      };
    },
    computed: {
      loggedUser() {
        return this.$store.getters.getLogged
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      route() {
        return this.$route
      }
    },
    methods: {
      searchData(){
        this.openSearchModal = !this.openSearchModal
        this.$router.push("/search/?value=" + this.searchField)

        if(this.$route.name=="Search"){
          location.reload();
        }
      },
      async logout() {
        //console.log('logout token', localStorage.getItem('LS_QTEDU_LOGOUT_TOKEN'))
        //console.log('csrf token', localStorage.getItem('LS_QTEDU_CSRF_TOKEN'))

        //MANUAL LOGOUT
        //window.open(process.env.VUE_APP_REDIRECT + 'user/logout', '_blank');


        //logout drupal api - BUG NOT WORKING !!
        /*var url = new URL(process.env.VUE_APP_ENDPOINT + "user/logout?_format=json&token=" + localStorage.getItem('LS_QTEDU_LOGOUT_TOKEN'));
        await fetch(url, {
          method: "POST",
          redirect: "follow",
          headers: {
            "X-CSRF-Token": localStorage.getItem('LS_QTEDU_CSRF_TOKEN')
          },
          withCredentials: true
        })
        .then(res => res.json())
        .then(json =>//console.log(json))*/

        this.$store.commit('SET_LOGOUT')

       //console.log('*** LOGGED OUT ***')

        window.location.href = process.env.VUE_APP_REDIRECT + 'user/logout'
        //console.log('logout token', localStorage.getItem('LS_QTEDU_LOGOUT_TOKEN'))
        //console.log('csrf token', localStorage.getItem('LS_QTEDU_CSRF_TOKEN'))
      },
      goto(string) {
        this.$router.push(string)
        this.closeMenu()
      },
      changeLang(l) {
        this.$store.commit('SET_CURRENT_LANG', l)
        this.closeMenu()
        document.getElementById('check').checked = false
      },
      buildTree(items) {
        var ch = []

        items.forEach(item => {
          if (item.has_children)
            ch.push({
              label: item.title,
              url: "",
              children: this.buildTree(item.subtree)
            })
          else
            ch.push({
              label: item.title,
              url: item.url,
              children: []
            })
        })

        return ch
      },
      async fetchMenuItems() {
        var items = []

        var l = this.lang != this.defaultLang ? ('/' + this.lang) : ''

        await fetch(`${process.env.VUE_APP_ENDPOINT}${l}jconfig/menu/main`)
          .then(res => res.json())
          .then(json => items = json.menu)

        this.menuItems = this.buildTree(items)
      },
      toggleMenu() {
        if (this.menuOpen)
          this.closeMenu()
        else
          this.openMenu()

        this.openSubmenu(null)
      },
      openMenu() {
        //document.body.classList.add('noscroll')
        //document.querySelector("html").classList.add("menu-open");
        document.body.classList.add("menu-open");
        document.getElementById('check').checked = true
        this.menuOpen = true

        //console.log('open menu')
      },
      closeMenu() {
        //console.log('close-menu')
        //document.body.classList.remove('noscroll')
        //document.querySelector("html").classList.remove("menu-open");
        document.body.classList.remove("menu-open");
        document.getElementById('check').checked = false
        this.openSubmenu(null)
        this.menuOpen = false

        //console.log('close menu')

        this.hideSubmenu()
      },
      hideSubmenu() {
        // hide temporary sub menu after click
        this.submenuVisible = false
        setTimeout(() => {
          this.submenuVisible = true
        }, 100)
      },
      //just one submenu can stay open at a time
      openSubmenu(index) {
        //console.log(index)
        if (this.submenuOpen === index)
          this.submenuOpen = null
        else
          this.submenuOpen = index
      },
      handleScroll() {
        if (window.pageYOffset)
          this.windowScrolled = true
        else
          this.windowScrolled = false
      },
      change(ev) {
        this.changeLang(ev.target.value)
      }
    },
    created() {
      this.fetchMenuItems();
      window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
      lang() {
        this.fetchMenuItems()
      }
    }
  };
</script>

<style lang="scss">
  @import '../../styles/variables.scss';
  @import '../../styles/search-header.scss';

  header nav:before {
    content: "";
    background-color: $primaryColor;
    width: 100%;
    //opacity: .8;
    height: 100%;
    z-index: 0;
    top: 0;
    right: 0px;
    display: block;
    position: absolute;
    transition: 0.3s ease;
    box-shadow: 0px 11px 20px 0px #00000012;
    transform: translateY(-100%);
    z-index: -1;
  }

  header nav>.container {
    padding-right: 0px;
    border-bottom: 1px #fff solid;
            justify-content: space-between;

  }

  header.sticky {
    nav:before {
      transition: 0.3s ease;
      transform: translateY(0%);
    }

    nav>.container {
      border: none;
      
    }
  }

  header.navigation .navbar .container .menu-list .container .navbar-nav {

    //generali
    .nav-item {

      &.active {
        .nav-item-content {
          span {
            //text-decoration: underline;
          }
        }


        .router-link-active {
          //text-decoration: underline;
        }
      }

      &:first-child {
        margin-top: 0px;
      }

      border-bottom: 1px solid rgb(224, 224, 224);

      span {
        font-weight: 500;
        font-size: 20px;
        font-family: $primaryFont;
      }

      .nav-item-content {
        padding: 1em 0.5em;
      }


      .submenu {


        .nav-item-content {
          padding: 1rem 0.5em 1rem 0.5em;

          a {
            color: $primaryColor;
            font-weight: 500;
            font-size: 1rem;
          }
        }
      }
    }


    // prime 2
    >.nav-item:nth-child(1),
    >.nav-item:nth-child(2) {
      .router-link-exact-active.router-link-active {
        color: #0018b5;
      }
    }


    // ultime 2
    >.nav-item:nth-child(3),
    >.nav-item:nth-child(4) {
      border-bottom: 0;

      .nav-item-content {
        padding: 2em 0.5em 1rem;

        &:after {
          top: 35px;
        }
      }

      .submenu.level1 {

        >li {
          padding-left: 1rem;

          //descrizioni testo 
          &:first-child>div:first-child {
            display: none;
          }

          //eccezione per outreach
          &:first-child:last-child {
            display: block;
          }

          span.nav-link::after {
            display: none;
          }
        }

        // TITOLO submenu ultimi 2 voci
        .nav-item-content {

          span {
            text-transform: none;
          }

          padding: 2rem 0.5em 1rem 0.5em;
        }

        //submenu ultimi 2 voci
        .submenu {

          .nav-item-content {
            padding: 0.5rem 0.5em 0.5em 0.5em;
          }

          >li {
            padding-left: 1rem;

            //descrizioni testo 
            &:first-child {
              display: block;
            }
          }
        }
      }


      span.nav-link {
        text-transform: uppercase;
        font-size: 1rem;
        position: relative;
        top: .25em;

        &::after {
          top: 3px;
          position: relative;
          margin-top: 5px;
          height: 6px;
          content: "";
          display: block;
          background-repeat: repeat-x;
          background-size: contain;
          background-image: url("../../assets/pattern-menu-header-orange.png");
        }

      }
    }

    >.nav-item:nth-child(3) {

      .submenu.level1 {
        >li {

          //descrizioni testo 
          &:first-child {
            display: none;
          }
        }
      }


      &.active {

        .router-link-active.router-link-exact-active,
        .nav-link {
          color: #FF0576;
        }
      }

      span.nav-link {
        &::after {
          background-image: url("../../assets/pattern-menu-header.svg");
        }
      }
    }

    >.nav-item:nth-child(4) {
      &.active {

        .router-link-active.router-link-exact-active,
        .nav-link {
          color: #FF7A07;
        }
      }
    }

    // ultima section
    .nav-item:last-child {
      background-color: #eaebf1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 2rem 0rem;
      margin-top: 2rem;
      margin: 2rem -1.44rem 0rem;

      .btn.slim {
        span {
          font-size: 16px;
        }
      }
    }
  }

  header.navigation .nav-item:nth-child(3) .submenu.level1 h4 {
    color: #FF0576;
  }

  header.navigation .nav-item:nth-child(4) .submenu.level1 h4 {
    color: #FF7A07;
  }

  /* @media (min-width: 768px) {
  header .nav-item:last-child {
    justify-content: flex-end;
  }
} */

  @media (min-width: 1200px) {


    body header.navigation nav .container .menu-list .navbar-nav .nav-item:nth-last-child(2):hover .submenu.level1>li:first-child>div:last-child {

      &>div {
        display: none;
      }

      a {
        background: linear-gradient(to right, #FF7A07 20%, #FF0576 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      a:hover {
        text-decoration: underline;
        -webkit-text-fill-color: #071152;
        color: #071152;
      }

    }


    header.navigation .navbar .container .menu-list .container .navbar-nav>.nav-item:nth-child(3) .submenu.level1,
    header.navigation .navbar .container .menu-list .container .navbar-nav>.nav-item:nth-child(4) .submenu.level1 {
      >li {
        padding-left: 1rem;

        //descrizioni testo 
        &:first-child {
          display: block;
        }

        &:first-child>div:first-child {
          display: block;
        }
      }
    }

    header.navigation .navbar .container .menu-list .container .navbar-nav>.nav-item:nth-child(3) .submenu.level1 .nav-item-content,
    header.navigation .navbar .container .menu-list .container .navbar-nav>.nav-item:nth-child(4) .submenu.level1 .nav-item-content {
      padding: 1rem 0.5em 1rem 0em;
    }

    .nav-item:last-child {
      display: block;
    }

    header.navigation nav .container .menu-list .navbar-nav .submenu.level1 {
      &:before {
        background: linear-gradient(180deg, black, transparent);
        position: absolute;
        width: 100%;
        height: 40px;
        content: "";
        top: 0;
        left: 0;
        opacity: 0.05;
      }
    }

    header.navigation .navbar .container {
      padding: 0;

      .menu-list .container .navbar-nav .nav-item {
        margin-right: 1rem;

        &:last-child {
          margin: 2rem 0rem 0rem;
        }

        //ultime 2 sezioni
        &:nth-last-child(3):hover .submenu.level1,
        &:nth-last-child(2):hover .submenu.level1 {
          padding: 2rem 3rem;
        }

        &:nth-last-child(2) span.nav-link,
        &:nth-last-child(3) span.nav-link {
          top: 0;
        }

        //join button
        &:nth-last-child(3) {
          .submenu.level1>li:first-child {
            .nav-link:not(.link-standard) {
              color: #fff;
              border-radius: 25px;
              background:
                $gradient padding-box,
                $gradient border-box;
              padding: 0.7rem 2rem;
            }
          }
        }

        // link sotto join button
        &:nth-last-child(3) {
          
          .submenu.level1>li:first-child {

            .nav-link.link-standard {

              background: linear-gradient(to right, #FF7A07 20%, #FF0576 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &:hover {
                color: #071152;
                text-decoration: underline;
                background: transparent;
                -webkit-text-fill-color: #071152;
              }
            }
          }
        }

        .submenu.level1 {
          padding: 1rem 1rem;

          li {
            padding-right: 2em;
            padding-left: 0em;

            .nav-item-content {
              padding: 0;
            }

            .submenu.level2 {
              li {
                padding-left: 0;
                padding-right: 0em;
              }

              .nav-item-content {
                padding: 0;
              }
            }
          }
        }

        &:nth-child(3) .submenu>li:first-child,
        &:nth-child(4) .submenu>li:first-child {
          display: block;
        }

        border-bottom: 0px solid #e0e0e0;

        &:last-child {
          background-color: transparent;
        }

        span {
          font-family: $secondaryFont;
          font-size: 1rem;
        }
      }
    }

    body header.navigation nav.navbar .container .menu-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    margin-right: 62px;
    margin-left: auto;

  }

  }













</style>
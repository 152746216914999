<template>
    <div :class="[nodetype]">
        <!--<h3>ArticlesGrid - Template lista contenuti ({{ nodetype }})</h3>-->
        <div class="articles--grid">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  components: {},
  name: 'articles-grid',
  data: () => {
    return {
      items: []
    }
  },
  props: {
    sortby: String,
    elementstoinclude: Array,
    nodetype: String
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
    <article class="generic">
        <router-link :to="detaillink" class="tile plain">
            <GrayCard 
                v-if="articleData && articleData.attributes"
                :pinned="pinned">

                <div class="col1"> 
                    <div>
                        <div class="article--tag label blue">
                            <span v-for="(t, ind) in articleData.field_material_typology" :key="'typology'+ind">{{ 
                                (t ? 
                                (t.attributes.name + (( articleData.field_material_typology.length > ind+1 ) ? ', ' : '')) 
                                : '') 
                            }}</span>
                        </div>
                        <h4 class="article--title gray">{{ articleData.attributes.title }}</h4>

                        <div class="article--teaser teaser" 
                            v-if="articleData.attributes.field_summary_and_description.summary">
                            <p>{{articleData.attributes.field_summary_and_description.summary}}</p>
                        </div>
                    </div>
                    <div>
                        <div class="article--meta">
                            <div class="targets">
                                <p v-for="(t, ind) in articleData.field_school_target" :key="'target_school_'+ind">
                                    <span v-if="t.attributes.name != 'Other (specify)'"
                                        class="icon-left target">{{ t.attributes.name }}</span>
                                </p>
                                <p v-for="(t, ind) in articleData.field_higher_education_target" :key="'target_higher_'+ind">
                                    <span v-if="t.attributes.name != 'Other (specify)'"
                                        class="icon-left target">{{ t.attributes.name }}</span>
                                </p>
                                <p v-for="(t, ind) in articleData.field_life_long_learning_target" :key="'target_lifelong_'+ind">
                                    <span v-if="t.attributes.name != 'Other (specify)'"
                                        class="icon-left target">{{ t.attributes.name }}</span>
                                </p>
                                <p v-for="(t, ind) in articleData.field_outreach_target" :key="'target_outreach_'+ind">
                                    <span v-if="t.attributes.name != 'Other (specify)'"
                                        class="icon-left target">{{ t.attributes.name }}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col2">
                    <div v-if="articleData.field_material_organization.length">
                        <p class="label">PROVIDED BY</p>
                        <div v-for="(org, ind) in articleData.field_material_organization" :key="'org'+ind">
                            <p class="bold">{{ 
                                org.attributes.field_org_name + 
                                ((org.attributes.field_org_name && org.attributes.field_org_mat_prg_city) ? ', ' : '') +
                                (org.attributes.field_org_mat_prg_city ? org.attributes.field_org_mat_prg_city : '') +
                                (((org.attributes.field_org_name || org.attributes.field_org_mat_prg_city) && org.attributes.field_org_mat_prg_country) ? ', ' : '') +
                                (org.attributes.field_org_mat_prg_country ? organizationCountry(org.attributes.field_org_mat_prg_country.country) : '')
                            }}</p>
                        </div>
                    </div>

                    <br>

                    <div v-if="articleData.field_material_language.length">
                        <p class="label">LANGUAGES</p>
                        <span class="teaser bold" v-for="(l, ind) in articleData.field_material_language" :key="'lang'+ind">
                            {{ 
                                l.attributes.name +
                                ((articleData.field_material_language.length-1 > ind) ? ', ' : '')
                            }}
                        </span>
                    </div>

                    <br>

                    <div class="buttons" v-if="this.articleData.attributes.field_url">
                        <a @click.stop :href="this.articleData.attributes.field_url.uri" target="_blank" class="btn slim plain border">
                            <span class="bold icon-right external">View online</span>
                        </a>
                        <!-- <a class="btn plain slim">
                            <span class="">DOWNLOAD</span>
                        </a> -->
                    </div>
                </div>
                
            </GrayCard>
        </router-link>
    </article>
</template>

<script>
import GrayCard from '../atomic-elements/GrayCard.vue'
export default {
    name: 'material-card',
    components: {
        GrayCard
    },
    data: () => {
        return {}
    },
    props: {
        pinned: Boolean,
        articleData: Object,
        cat: Object,
        routesData: Array
    },
    computed: {
        organization() {
            if(this.articleData.field_material_organization && this.articleData.field_material_organization.length)
                return this.articleData.field_material_organization[0].attributes
            else return null
        },
        detaillink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid )

            if(link.length>0)
                path = link[0].path
            else
                path = '/not-found'

            return path
        }
    },
    methods: {
        organizationCountry(code) {
            return this.$store.getters.getCountry(code)
        }   
    },
    mounted() {
        this.articleData.field_material_typology = this.articleData.field_material_typology.filter(m => m && m.attributes.name!='Other')
    }
}
</script>

<style lang="scss" scoped>
.col1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.col2 { 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    p { margin: 0; }
}
</style>
<template>
    <keep-alive>
        <!-- if it's a top level route menu, it is a link -->
        <router-link class="sitemap-link plain" :to="item.url" v-if="item.children.length == 0">
            {{ item.label }}
        </router-link>

        <div v-else>
            <!-- if has children is not clickable -->
            <span>{{ item.label }}</span>

            <!-- if it has children, create a list of sub links -->
            <!--<ul :class="['submenu', submenuOpen === index ? 'active' : '']" >-->
            <div :class="['submenu', 'level'+level]">
                <!-- create a clickable link for every child -->
                <recursive-link class="sitemap-link plain" 
                    v-for="(child, index) of item.children" :key="index" 
                    :item="child" :level="level+1" ></recursive-link>
            </div>
        </div>

    </keep-alive>
</template>

<script>
export default {
    name: 'recursive-link',
    props: {
        item: Object,
        level: Number
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
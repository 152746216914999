import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import countries from '../json/countrylist.json'

export default new Vuex.Store({
  state: {
    lastPage: '',
    currentLang: 'en',
    defaultLang: '',
    languages: [],
    countrylist: countries.data,
    routesData: [],
    workingGroups: [],
    logout_token: '',
    csrf_token: '',
    logged_id: '',
    logged_email: '',
    user_name: '',
    user_roles: [],
    temp_internship: false,
    add_wg_filter: ''
  },
  mutations: {

    SET_CURRENT_LANG: function(state, payload) {
      state.currentLang = payload;
    },
    SET_LAST_PAGE: function(state, payload) {
      state.lastPage = payload;
    },
    SET_DEFAULT_LANG: function(state, payload) {
      state.defaultLang = payload;
    },
    SET_LANGUAGES: function(state, payload) {
      state.languages = payload;
    },
    SET_ROUTES: function(state, payload) {
      state.routesData = payload;
    },
    SET_WGS: function(state, payload) {
      state.workingGroups = payload;
    },
    SET_LOGIN: function(state, payload) {
      //console.log(payload)

      state.logout_token = payload.logout_token
      state.csrf_token = payload.csrf_token
      state.logged_id = payload.current_user.uid
      state.logged_email = payload.current_user.name

      localStorage.setItem('LS_QTEDU_LOGOUT_TOKEN', payload.logout_token)
      localStorage.setItem('LS_QTEDU_CSRF_TOKEN', payload.csrf_token)
      localStorage.setItem('LS_QTEDU_LOGGED_ID', payload.current_user.uid)
      localStorage.setItem('LS_QTEDU_LOGGED_EMAIL', payload.current_user.name)
    },  
    SET_LOGOUT: function(state) {
      state.logout_token = ''
      state.csrf_token = ''
      state.logged_id = ''

      localStorage.removeItem('LS_QTEDU_LOGOUT_TOKEN')
      localStorage.removeItem('LS_QTEDU_CSRF_TOKEN')
      localStorage.removeItem('LS_QTEDU_LOGGED_ID')
      localStorage.removeItem('LS_QTEDU_LOGGED_EMAIL')
    },
    SET_INTERNSHIP: function(state, payload) {
      state.temp_internship = payload
    },
    SET_WG_FILTER: function(state, payload) {
      state.add_wg_filter = payload
    }
  },
  getters: {
    getCountry: (state) => (id) => {
      var country = state.countrylist.filter(c => c.attributes.drupal_internal__id == id)
      if(country.length>0) {
        //console.log(country[0].attributes)
        if(id=="156")
          return 'The Netherlands'
        else return country[0].attributes.name
      }
        
      else return 'country ' + id + ' not found'
    },
    getISO3: (state) => (id) => {
      var country = state.countrylist.filter(c => c.attributes.drupal_internal__id == id)
      if(country.length>0)
        return country[0].attributes.iso3
      else return 'country ' + id + ' not found'
    },
    getWG: (state) => (id) => {
      var wg = state.workingGroups.filter(c => c.attributes.drupal_internal__nid == id)
      var wgdata = {
        nid: 0,
        name: 'wg not found'
      }

      if(wg.length>0)
        wgdata = {
          nid: wg[0].attributes.drupal_internal__nid,
          name: wg[0].attributes.title
        }
      
      return wgdata
    },
    getLogged: (state) => {
      return state.logout_token!='' && state.csrf_token!='' && state.logout_token!=null && state.csrf_token!=null 
    }
  },
  actions: {}
});
<template>
  <footer class="footer">

    <div class="sitemap">
      <div class="container">
        <div class="footer-column" v-for="(item, index) in this.menuItems" :key="'footer' + index">
          <RecursiveFooterLink :item="item" :level="0" />
        </div>
      </div>
    </div>

    <div class="bottom-footer">
      <div class="container">
        <div class="qt-section">
          <div class="logo">
            <img src="@/assets/QTEdu-logo-colors-white.svg" alt="logo" class="logo logo-desktop" />
          </div>

          <div class="nav-item">
            <button @click="goto()" class="btn slim"><span>Get involved</span></button>
            <router-link v-if="!loggedUser" to="/login" class="login btn plain slim light"><span
                class="icon-right profile">Login</span></router-link>
            <button v-if="loggedUser" @click="logout()" class="login btn plain slim light"><span
                class="icon-right profile">Logout</span></button>
          </div>
        </div>
        <div class="partners">
          <div>
            <img src="@/assets/logo-europe.jpg" alt="logo-europe" width="auto" height="140">
            <p>The QTEdu CSA Project is funded by the European Union’s Horizon 2020 research and innovation programme
              under grant agreement number 951787.</p>
          </div>
          <div>
            <img src="../../assets/qt-flagship-logo.svg" width="auto" height="140" alt="Quantum Technology's logo">
            <p>QTEdu CSA assists the European Quantum Flagship with the creation of the learning ecosystem necessary to inform and educate society about quantum technologies.</p>
          </div>
        </div>
        <div class="links">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/cookie-policy">Cookie Policy</router-link>
          <div class="email">
            <a href="mailto:info@qtedu.eu">info@qtedu.eu</a>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
  import RecursiveFooterLink from '../atomic-elements/RecursiveFooterLink.vue'
  export default {
    name: 'the-footer',
    data: () => {
      return {
        menuItems: []
      }
    },
    components: {
      RecursiveFooterLink
    },
    methods: {
      async logout() {
        //MANUAL LOGOUT
        window.open(process.env.VUE_APP_REDIRECT + 'user/logout', '_blank');
        this.$store.commit('SET_LOGOUT')
       //console.log('*** LOGGED OUT ***')
      },
      goto() {
        this.$router.push('/get-involved')
      },
      buildTree(items) {
        //console.log(items)
        var ch = []

        items.forEach(item => {
          //console.log(item)
          if (item.has_children)
            ch.push({
              label: item.title,
              url: "",
              children: this.buildTree(item.subtree)
            })
          else
            ch.push({
              label: item.title,
              url: item.url,
              children: []
            })
        })

        return ch
      },
      async fetchMenuItems() {
        var items = []

        var l = this.lang != this.defaultLang ? ('/' + this.lang) : ''

        await fetch(`${process.env.VUE_APP_ENDPOINT}${l}jconfig/menu/main`)
          .then(res => res.json())
          .then(json => items = json.menu)

        this.menuItems = this.buildTree(items)
      },
    },
    computed: {
      loggedUser() {
        return this.$store.getters.getLogged
      },
    },
    mounted() {
      this.fetchMenuItems();
    }
  }
</script>

<style scoped lang="scss">
</style>
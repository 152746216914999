<template>
  <div class="page-layout details working-group">
    <HeaderIntroImage underlined central target :categoryData="categoryData" :headerMetadata="wgData">
      <p class="description teaser hide" ref="description" v-html="description"></p>
      <button @click="toggleDescription" class="btn slim border">
        <span class="icon-right read-more" ref="read_more_icon">More</span>
      </button>
    </HeaderIntroImage>

    <div>
      <div class="container" v-if="newsData && newsData.length">
        <router-link class="icon-right long-arrow internal-meetings" @click.native="addWGfilter" to="/news">
            <h2 class="">News</h2>
          </router-link>
        <BlockCarousel dotted>
          <ArticleCard v-for="(n, index) in newsData" :key="'news'+index" :cat="categoryData" :articleData="n"
            :routesData="routesData" />
        </BlockCarousel>
      </div>

      <div class="fullwidth gray-bg" v-if="projectData && projectData.length">
        <div class="container">
          <h2>Projects</h2>
          <ArticlesGrid class="slim-two">
            <ProjectCardMin v-for="(pj, index) in this.projectData" :key="'pj'+index" :projectData="pj"
              :routesData="routesData" />
          </ArticlesGrid>
          <br>
        </div>
      </div>

      <div v-if="wgData" :class="['dark-bg fullwidth banner', 'wg-'+categoryData.nid]" style="margin-top: 0;">
        <div class="container">
          <p class="label blue">JOIN THE</p>
          <h2>{{ wgData.attributes.title }}</h2>
          <h3>Working Group</h3>
          <br>
          <button @click="goto()" class="btn"><span>JOIN</span></button>
        </div>
      </div>

      <div class="fullwidth gray-bg" v-if="eventsData && eventsData.length">
        <div class="container">
          <router-link class="icon-right long-arrow internal-meetings" @click.native="addWGfilter" to="/events">
            <h2 class="">Internal Meetings</h2>
          </router-link>
          <BlockCarousel>
            <EventCardMin v-for="(ev, index) in this.eventsData" :key="'event'+index" :routesData="routesData"
              :cat="categoryData" :articleData="ev" />
          </BlockCarousel>
        </div>
      </div>

      <div class="container" style="position: relative; margin-top: 3rem;"
        v-if="wgData && this.membersNum">
        <!-- (wgData.field_ref_member || wgData.field_ref_coordinator || wgData.field_ref_assistant)"> -->
        <h2>Members</h2>
        <div class="baloon">
          <span class="num">{{ wgData.field_ref_member.length + wgData.field_ref_assistant.length + wgData.field_ref_coordinator.length }}</span>
          <span class="bold blue members">Total<br>members</span>
        </div>
        <p class="members-teaser teaser">Here below the list of members' profile who authorized personal data
          publication.</p>

        <FiltersBlock @reset="reset">
          <div class="col1">
            <div class="row1">
              <div class="input-icon-right search">
                <input type="text" v-model="searchField" placeholder="Search by name and/or surname"
                  @input="searchAfterTyping">
              </div>
            </div>
            <div class="row2">
              <select name="country" class="filters--dropdown" v-model="countryFilter">
                <option value="all">Select a country</option>
                <option v-for="(c, ind) in this.countryList" :key="'country'+ind" :value="c">{{ getCountry(c) }}</option>
              </select>
              <select name="project" class="filters--dropdown" v-model="projectFilter">
                <option value="all">Select a Project</option>
                <option v-for="(p, ind) in this.projectData" :key="'poption'+ind"
                  :value="p.attributes.drupal_internal__nid">{{ p.attributes.title }}</option>
              </select>
              <div class="competence">
                <select name="competence" class="filters--dropdown" v-model="competenceFilter">
                  <option value="all">Select a Competence</option>
                  <option v-for="(c, ind) in this.competenceList" :key="'compoption'+ind" :value="c">{{ c }}</option>
                </select>
              </div>
              <div class="resource">
                <select name="resource" class="filters--dropdown" v-model="resourceFilter">
                  <option value="all">Select a Resource</option>
                  <option v-for="(c, ind) in this.resourceList" :key="'compoption'+ind" :value="c">{{ c }}</option>
                </select>
              </div>
            </div>
          </div>
        </FiltersBlock>

        <ArticlesGrid>
          <MemberCardMin v-for="(l, index) in filterMembers(wgData.field_ref_coordinator)" wgcoordinator :key="index + l.id"
            :memberData="l" :routesData="routesData" />
          <MemberCardMin v-for="(a, index) in filterMembers(wgData.field_ref_assistant)" assistant :key="index + a.id"
            :memberData="a" :routesData="routesData" />
          <MemberCardMin v-for="(m, index) in filterMembers(wgData.field_ref_member)" :key="index + m.id" :memberData="m"
            :routesData="routesData" />
        </ArticlesGrid>
      </div>

    </div>

    <div class="container" v-if="this.loading">
      <br>
      <br>
      <h3>Loading data ...</h3>
    </div>

  </div>
</template>

<script>
  import FiltersBlock from '../components/layout-blocks/FiltersBlock.vue'
  import BlockCarousel from '../components/layout-blocks/BlockCarousel.vue'
  import ArticlesGrid from '../components/layout-blocks/ArticlesGrid.vue'
  import ProjectCardMin from '../components/layout-blocks/ProjectCardMin.vue'
  import ArticleCard from '../components/layout-blocks/ArticleCard.vue'
  import EventCardMin from '../components/layout-blocks/EventCardMin.vue'
  import MemberCardMin from '../components/layout-blocks/MemberCardMin.vue'
  import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
  import {
    fetchNodes
  } from '../libs/drupalClient'
  export default {
    name: 'working-group',
    components: {
      HeaderIntroImage,
      FiltersBlock,
      BlockCarousel,
      ArticlesGrid,
      ProjectCardMin,
      ArticleCard,
      EventCardMin,
      MemberCardMin
    },
    props: {},
    data: () => {
      return {
        wgData: null,
        projectData: null,
        newsData: null,
        eventsData: null,
        countryFilter: 'all',
        projectFilter: 'all',
        competenceFilter: 'all', 
        resourceFilter: 'all',
        searchFilter: '',
        searchField: '',
        timer: undefined,
        loading: true
      }
    },
    computed: {
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      category() {
        return this.$route.params.title
      },
      routesData() {
        return this.$store.state.routesData
      },
      description() {
        if (this.wgData && this.wgData.attributes)
          return this.wgData.attributes.field_description.value
        else
          return ''
      },
      countryList() {
        var cs = []

        if (this.wgData.field_ref_member.length)
          this.wgData.field_ref_member.forEach(m => {
            if (m.field_organization[0].attributes.field_country) {
              //let country = this.$store.getters.getCountry(m.field_organization[0].attributes.field_country.country)
              let country = m.field_organization[0].attributes.field_country.country
              if (!cs.includes(country))
                cs.push(country)
            }
          })

        cs.sort((a, b) => {
          return this.$store.getters.getCountry(a) > this.$store.getters.getCountry(b) ? 1 : -1
        })

        return cs
      },
      competenceList() {
        var cms = []

        if (this.wgData.field_ref_member.length)
          this.wgData.field_ref_member.forEach(m => {
            m.field_competence_offer.forEach(cp => {
              if(!cms.includes(cp.attributes.name) && cp.attributes.name!='Other (specify)') 
                cms.push(cp.attributes.name)
            })
          })

        cms.sort((a, b) => { return a > b ? 1 : -1 })

        return cms
      },
      resourceList() {
        var rs = []

        if (this.wgData.field_ref_member.length)
          this.wgData.field_ref_member.forEach(m => {
            m.field_resource_offer.forEach(r => {
              if(!rs.includes(r.attributes.name) && r.attributes.name!='Other (specify)') 
                rs.push(r.attributes.name)
            })
          })

        rs.sort((a, b) => { return a > b ? 1 : -1 })

        return rs
      },
      categoryData() {
        var cd = {
          nid: 0,
          name: ""
        }
        switch (this.category) {
          case 'school-education-and-public-outreach':
            cd.nid = 14
            cd.name = 'School education and Public outreach'
            break;
          case 'educational-initiatives-higher-education':
            cd.nid = 15
            cd.name = 'Educational initiatives in higher education'
            break;
          case 'lifelong-learning-and-workforce-training':
            cd.nid = 16
            cd.name = 'Lifelong learning and workforce training'
            break;
          case 'educational-research-quantum-technology':
            cd.name = "Educational research in Quantum Technology"
            cd.nid = 17
            break;
          case 'equity-and-inclusion-qt-educational-initiatives':
            cd.nid = 18
            cd.name = 'Equity and inclusion for QT educational initiatives'
            break;
          default:
            cd.nid = 0
            cd.name = ""
            break;
        }
        return cd
      },
      ref_members() {
        if (this.wgData.field_ref_member)
          return this.wgData.field_ref_member
            .filter(m => m != null)
            //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })
            .sort((a, b) => {
              return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
            })
        else
          return []
      },
      ref_coordinators() {
        if (this.wgData.field_ref_coordinator)
          return this.orderMembers(this.wgData.field_ref_coordinator.filter(m => m != null))
        else
          return []
      },
      ref_assistant() {
        if (this.wgData.field_ref_assistant)
          return this.orderMembers(this.wgData.field_ref_assistant.filter(m => m != null))
        else
          return []
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      membersNum() {
        var ns = 0
        
        if(this.wgData.field_ref_member && this.wgData.field_ref_coordinator && this.wgData.field_ref_assistant) {
          if(this.loggedUser)
            ns = this.wgData.field_ref_member.length 
              + this.wgData.field_ref_coordinator.length 
              + this.wgData.field_ref_assistant.length

          else {
            ns = this.wgData.field_ref_member.filter(m => 
              (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
              
            ns = ns + this.wgData.field_ref_coordinator.filter(m => 
              (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length

            ns = ns + this.wgData.field_ref_assistant.filter(m => 
              (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
          }
        }

        return ns
      }
    },
    methods: {
      getCountry(c) {
        return this.$store.getters.getCountry(c)
      },
      addWGfilter() {
        this.$store.commit('SET_WG_FILTER', this.wgData.attributes.title)
      },
      searchAfterTyping() {
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
          this.searchFilter = this.searchField
        }, 300);
      },
      filterMembers(array) {
        var mms = []

        if (array.length) {
          mms = array

          if (this.projectFilter != 'all') {
            mms = mms.filter(e => {
              let found = false

              let pj = this.projectData.find(p => p.attributes.drupal_internal__nid == this.projectFilter)

              pj.field_ref_member.forEach(m => {
                if (m.attributes.drupal_internal__nid == e.attributes.drupal_internal__nid)
                  found = true
              })

              pj.field_pilot_project_coordinator.forEach(m => {
                if (m.attributes.drupal_internal__nid == e.attributes.drupal_internal__nid)
                  found = true
              })

              return found
            })
          }

          if (this.countryFilter != 'all') {
            mms = mms.filter(e => {
             //console.log('mms',mms);
              if (e.field_organization[0].attributes.field_country)
                //return this.countryFilter == this.$store.getters.getCountry(e.field_organization[0].attributes.field_country.country)
                return this.countryFilter == e.field_organization[0].attributes.field_country.country
              else
                return false
            })
          }

          if (this.competenceFilter != 'all') {
            mms = mms.filter(e => {
              var found = false
              if(e.field_competence_offer)
                e.field_competence_offer.forEach(c => {
                  if(c.attributes.name == this.competenceFilter)
                    found = true
                })
              return found
            })
          }

          if (this.resourceFilter != 'all') {
            mms = mms.filter(e => {
              var found = false
              if(e.field_resource_offer)
                e.field_resource_offer.forEach(c => {
                  if(c.attributes.name == this.resourceFilter)
                    found = true
                })
              return found
            })
          }

          if (this.searchFilter != '') {
            mms = mms.filter(e => e.attributes.title.toLowerCase().includes(this.searchFilter.toLowerCase()))
          }
        }

        return mms
      },
      reset() {
        this.countryFilter = 'all'
        this.projectFilter = 'all'
        this.competenceFilter = 'all'
        this.resourceFilter = 'all'
        this.searchFilter = ''
        this.searchField = ''
      },
      goto() {
        this.$router.push('/login')
      },
      toggleDescription() {
        this.$refs.description.classList.toggle("hide")
        this.$refs.read_more_icon.classList.toggle("upside")

        if (!this.$refs.description.classList.contains('hide'))
          this.$refs.read_more_icon.innerHTML = 'Close'
        else
          this.$refs.read_more_icon.innerHTML = 'More'
      },
      async findCT() {
        this.loading = true

        await fetchNodes('working_group', {
            filters: [{
              key: 'drupal_internal__nid',
              value: this.categoryData.nid
            }]
          })
          .then(res => {
            this.wgData = res[0]
            //this.loading = false
            //console.log('data 1, working group', res)
          })

        await fetchNodes('pilot_project', {
            include: ['field_ref_working_group', 'field_logo', 'field_ref_member',
              'field_pilot_project_coordinator'
            ],
            filters: [{
              key: 'field_ref_working_group.drupal_internal__nid',
              value: this.categoryData.nid
            }]
          })
          .then(res => {
            this.projectData = res
            //console.log('data 2, projects', res)
          })

        await fetchNodes('news', {
            include: ['field_ref_working_group', 'field_image'],
            filters: [{
              key: 'field_ref_working_group.drupal_internal__nid',
              value: this.categoryData.nid
            }]
          })
          .then(res => {
           //console.log('res',res);
            this.newsData = res.sort((a, b) => {
              return a.attributes.field_date > b.attributes.field_date ? -1 : 1
            })
            //console.log('data 3, news', res)
          })

        await fetchNodes('event', {
            include: ['field_ref_working_group'],
            filters: [{
              key: 'field_ref_working_group.drupal_internal__nid',
              value: this.categoryData.nid
            }]
          })
          .then(res => {
            this.eventsData = res
            //console.log('data 4, events', this.eventsData)
          })

        await fetchNodes('working_group', {
            include: ['field_ref_assistant', 'field_ref_coordinator', 'field_ref_member'],
            nested_include: [
              'field_ref_coordinator.field_organization', 'field_ref_coordinator.field_photo', 'field_ref_coordinator.field_competence_offer', 'field_ref_coordinator.field_resource_offer',
              'field_ref_member.field_organization', 'field_ref_member.field_photo', 'field_ref_member.field_competence_offer', 'field_ref_member.field_resource_offer',
              'field_ref_assistant.field_organization', 'field_ref_assistant.field_photo', 'field_ref_assistant.field_competence_offer', 'field_ref_assistant.field_resource_offer',
            ],
            filters: [{
              key: 'drupal_internal__nid',
              value: this.categoryData.nid
            }]
          })
          .then(res => {
            this.wgData = res[0]
            this.wgData.field_ref_member = this.wgData.field_ref_member
              .filter(m => m != null)
              .sort((a, b) => {
                return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
              })
            this.wgData.field_ref_coordinator = this.orderMembers(this.wgData.field_ref_coordinator.filter(m => m != null))
            this.wgData.field_ref_assistant = this.orderMembers(this.wgData.field_ref_assistant.filter(m => m != null))
            //console.log('data 5, members data', this.wgData)
          })

          this.loading = false
      },
      orderMembers(array) {
        let first = array.filter(m => m.attributes.field_coordinator_type == '1')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let second = array.filter(m => m.attributes.field_coordinator_type == '2')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let third = array.filter(m => m.attributes.field_coordinator_type == null)
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        return first.concat(second).concat(third)
      }
    },
    mounted() {
      if(this.categoryData.name=="")
        this.$router.push('/not-found')

      this.reset()
      this.findCT()
    },
    watch: {
      category() {
        this.loading = true

        this.countryFilter = 'all'
        this.projectFilter = 'all'

        this.wgData = null
        this.eventsData = null
        this.projectData = null
        this.newsData = null

        this.findCT()

        if (!this.$refs.description.classList.contains('hide'))
          this.toggleDescription()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.working-group ::v-deep .header-intro .underlined h1:before { display: none; }

.search { 
  input { height: 3em; }
  &:before { top: 2em; }
}



.internal-meetings {
  background: none;
  background-clip: unset;
  -webkit-text-fill-color: unset;
  text-decoration: none;
  width: 100%;
  display: block;

  &:before { display: none; }

  &:hover {
    text-decoration: none;

    h2 {
      background: $gradient;
      background-clip: text;
      -webkit-text-fill-color: transparent;

    }
  }
}

.active-filter {
  padding: .25em;
  font-size: 14px;
  color: $middleBlue;

  &:before { padding-right: .5em; }

  &:hover {
    background-color: $middleBlue;
    color: #fff;
    cursor: pointer;
  }
}

.header-intro { transition: all 300ms ease; }

.description {
  height: 100%;
  transition: all 300ms ease;

  p, ::v-deep p { margin: 0 auto; }

  &.hide {
    height: 0px;
    overflow: hidden;
    transition: all 300ms ease;
  }
}

.members-teaser { width: 45%; }

.baloon {
  position: absolute;
  right: .25rem;
  top: 2rem;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  border: 1px dashed #333;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;


  .num {
    font-size: 3em;
    margin-top: -27px;
    background-image: $gradient;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: $primaryFont;
  }
}

.filters-block { margin-top: 3rem; }

@media (min-width: 345px) {
  .baloon { right: 1rem; }
}

@media (min-width: 400px) {
  .members-teaser { width: 55%; }
  .baloon {top: 0; }
}

@media (min-width: 500px) {
  .filters-block { margin-top: 4rem; }
}

@media (min-width: 768px) {
  .filters-block { margin-top: 6rem; }
  .baloon {top: 0; }
  .internal-meetings:before { display: block; }
}

@media (min-width: 1024px) {
  .filters-block { margin-top: 8rem; }

  .baloon {
    right: 2rem;
    width: 190px;
    height: 190px;

    .num { font-size: 4.5rem; }
    .members { font-size: 1.25rem; }
  }
}
</style>
<template>
    <div class="pannello">
        <div class="map" ref="map"></div>

        <div v-if="this.mapData" class="map-info">
            <h3>Resources</h3>
            <div class="wg-map-info">
                <div class="box">
                    <p class="num">{{ this.mapData.tot.programs }}</p>
                    <p class="didascalia">Programs, courses and trainings</p>
                </div>

                <div class="box">
                    <p class="num">{{ this.mapData.tot.materials }}</p>
                    <p class="didascalia">Material and tools</p>
                </div>

                <div class="box">
                    <p class="num">{{ this.mapData.tot.projects }}</p>
                    <p class="didascalia">Pilot Projects</p>
                </div>

                <router-link class="btn plain" to="/qtedu-european-community">
                    <span>Go to interactive map</span>
                </router-link>
            </div>
        </div>

        <div class="map-country-baloon" v-if="this.mapData">
            <h2>QTEdu in numbers</h2>

            <WhiteCard>

                <p class="num">{{ this.totMembers }}</p>
                <p class="didascalia">QTEdu Community Members</p>
            </WhiteCard>

            <div class="legend-grid">
                <div class="box">
                    <p class="icon-left exp1">{{ this.mapData.all_workgroups.members.experience_1 }}</p>
                    <p>Early career</p>
                </div>
                <div class="box">
                    <p class="icon-left exp2">{{ this.mapData.all_workgroups.members.experience_2 }}</p>
                    <p>Mid career</p>
                </div>
                <div class="box">
                    <p class="icon-left exp3">{{ this.mapData.all_workgroups.members.experience_3 }}</p>
                    <p>Advanced career</p>
                </div>
                <div class="box">
                    <p class="icon-left world">{{ this.numCountries }}</p>
                    <p>Countries</p>
                </div>
                <div class="box">
                    <p class="icon-left wgs">5</p>
                    <p>Working groups</p>
                </div>
            </div>
        </div>

        <div class="legend-line">
            <p>Number of members in countries:</p>
            <div class="bar"></div>
        </div>


    </div>
</template>

<script>
    // TODO Gestione di dataset da mapbox
    // https://studio.mapbox.com/datasets/
    // API https://docs.mapbox.com/mapbox-gl-js/api/

    import mapboxgl from 'mapbox-gl'
    mapboxgl.accessToken =
        'pk.eyJ1IjoiaW50ZXJmYXNlIiwiYSI6ImNqYW8zZGJkeTRjNXQzNHIxYmJpMWF6MnkifQ.TfWAUiqo6rDYVGid30Q6sA'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import WhiteCard from '../components/atomic-elements/WhiteCard.vue'
    import {fetchNodes  } from '../../src/libs/drupalClient'
    export default {
        name: 'mapbox',
        data() {
            return {
                map: null,
                mapData: null,
                numCountries: 0,
                countryKeys: [],
                //darkMapColor: '#022140',
                //baseMapColor: '#0a2e52',
                darkMapColor: '#071150',
                baseMapColor: '#0f1b66',
                lightMapColor: 'rgb(220, 220, 220)',
                hoverMapColor: 'rgb(250, 250, 250)',
                zoomMap:null,
                centerMap:null,
                totMembers:''
            };
        },
        components: {
            WhiteCard
        },
        computed: {
            route() {
                return this.$route
            }
        },
        methods: {

            async fetchMapData() {
                var tofetch = new URL(process.env.VUE_APP_ENDPOINT + 'mapdata')

                await fetch(tofetch, {
                        method: 'POST'
                    })
                    .then(res => res.json())
                    .then((json) =>{
                        this.mapData = json.data
                       console.log('this.mapData',this.mapData);
                    })
            },

            calcMembersTot(){
                console.log('calcMembersTot');
                fetchNodes('member',false).then(res => {
                    console.log('res',res.length);
                    this.totMembers = res.length
                })
            },
            mapOptions(){
                //console.log(window.innerWidth);

                if(window.innerWidth<768){
                    this.zoomMap = 2.3
                    this.centerMap = [10, 60]
                } else if (window.innerWidth<1200){
                    this.zoomMap = 3
                    this.centerMap = [4, 50]
                } else {
                    this.zoomMap = 3
                    this.centerMap = [10, 50]
                }
                
            },

            buildMap() {
                
                var vm = this

                this.map = new mapboxgl.Map({
                    container: this.$refs.map,
                    style: 'mapbox://styles/interfase/ckz5m7u5y001415qo3xjqm2q9?fresh=true',
                    zoom: this.zoomMap,
                    center: this.centerMap,
                    interactive: false,
                })

                this.map.on('load', () => {

                    this.map.addSource('countries', {
                        type: 'vector',
                        url: 'mapbox://mapbox.country-boundaries-v1'
                    });

                    this.map.addLayer({
                        'id': 'state-fills',
                        'type': 'fill',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            //'fill-color': '#474f8a',
                            'fill-color': vm.baseMapColor,
                            'fill-opacity': 1
                        }
                    }, 'country-labels');

                    this.map.addLayer({
                        'id': 'state-stroke',
                        'type': 'line',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            //'line-color': '#0b1665',
                            'line-color': vm.darkMapColor,
                            'line-width': 0.3
                        }
                    }, 'country-labels');

                    const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

                    // Calculate color values for each country based on 'hdi' value
                    var max = 0
                    var rows = Object.entries(this.mapData.all_workgroups.country)

                    this.numCountries = rows.length

                    for (const row of rows) {
                        if (row[1] > max) max = row[1]
                    }

                    for (const row of rows) {
                        // Convert the range of data values to a suitable color

                        const alpha = (row[1] / max) + 0.2;
                        //var color = `rgba(255, 255, 255, ${alpha})`
                        var color = `rgba(220, 220, 220, ${alpha})`

                        matchExpression.push(row[0], color);
                    }

                    // Last value is the default, used where there is no data
                    //matchExpression.push('#474f8a');
                    matchExpression.push(this.baseMapColor)

                    this.map.addLayer({
                        'id': 'countries-data',
                        'type': 'fill',
                        'source': 'countries',
                        'source-layer': 'country_boundaries',
                        'paint': {
                            'fill-color': matchExpression
                        }
                    }, 'state-stroke');
                })
            }
        },
        async mounted() {
            this.mapOptions()

            await this.fetchMapData()
            await this.buildMap()
            await this.calcMembersTot()
        }
    }
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.map {
    position: relative;
    height: 85vh;
    top: 0;
    bottom: 0;
    width: 100%;
}

.pannello {
    position: relative;
}

.map-info {
    font-size: 2em;
    text-align: center;
    pointer-events: none;
    padding: 1.5rem 0rem;

    h3 {
        margin: .5em 15px 0 15px;
        color: $primaryColor;
    }
}

.wg-map-info {
    display: grid;
    gap: 0.5rem;
    padding: 0 15px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    .box {
        max-width: 150px;
        margin: 1rem auto 0 auto;

        .num {
            font-size: 3rem;
            margin: 0;
            font-family: $primaryFont;
            color: $primaryColor;
        }

        .didascalia {
            margin-top: 1.2rem;
            font-size: 14px;
            line-height: 1.2rem;
            color: $primaryColor;
        }
    }

    a { 
        margin: 1rem 0 auto auto; 
        pointer-events: all;
        grid-column: span 3;
    }
}

.map-country-baloon {
    position: absolute;
    top: .5em;
    z-index: 3;
    width: 100%;
    padding: 0 15px;
    height: fit-content;

    h2 { color: #fff; }

    .white-card {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0 1rem 0;

        p {
            margin: 0;
            line-height: unset;
        }

        .didascalia {
            font-family: $primaryFont;
            font-size: 20px;
            padding: 0 2rem;
            margin-top: 0.5rem;
        }

        .num {
            font-size: 34px;
            background-image: $gradient;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: $primaryFont;
        }
    }
}

.legend-grid {
    display: block;
    position: absolute;
    .box { display: none; }
}

.legend-line {
    display: none;
    position: absolute;
    width: 90%;
    max-width: 400px;
    bottom: 43%;
    left: 50%;
    transform: translateX(-50%);

    p {
        color: #fff;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 40px;
    }
    
    .bar {
        width: 100%;
        height: 1em;
        border-radius: 1em;
        background: linear-gradient(to right, #0f1b66 10%, rgb(220, 220, 220));
        display: block;
        position: relative;
        margin-top: 8px;

        &:before,
        &:after {
            display: block;
            position: absolute;
            color: #fff;
            font-size: 13px;
            top: -23px;
        }

        &:before {
            content: "Low";
            left: 0;
        }

        &:after {
            content: 'High';
            right: 0;
        }
    }
}
 

@media (min-width: 400px) {
    .legend-line { bottom: 40%; }
}

@media (min-width: 768px) {
    .map-info {
        position: absolute;
        right: .5rem;
        top: .5rem;
        z-index: 3;
        text-align: right;

        h3 {
            margin: 0 1rem .5em auto;
            color: #fff;
        }
    }

    .wg-map-info {
        grid-template-columns: 1fr;
        border-top: 2px dashed #fff;
        .box {
            margin: 0;
            .num { color: #fff; }
            .didascalia { color: #fff; }
            &:first-child { margin: 1rem 0 0 auto; }
        }
        a { grid-column: span 1;}

    }

    .map-country-baloon {
        left: 9vw;
        height: 100%;
        .white-card { width: 50vw; }
    }
    
    .map-info {
        background: none;
        right: 5vw;
        top: 2rem;
    }

    .wg-map-info {
        width: 300px;
        .box { margin-left: auto; }
    }

    .map-country-baloon {
        left: 5vw;
        width: 33vw;
        .white-card {
            width: 100%;
            .didascalia { font-size: 1.5rem; }
            .num { font-size: 4rem; }
        }
    }

    .legend-grid {
        width: 33vw;
        .box p.icon-left { font-size: 2em; }
    }

    .legend-line {
        bottom: 5%;
        left: 5%;
        transform: none;
    }
}

@media (min-width: 992px) {
    .legend-grid {
        width: 440px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: .5em;

        .box {
            border-bottom: 1px dashed rgba(#fff, .7);
            margin: .25em 0;
            padding: 1rem 0.2rem 1rem 0.8rem;
            padding-bottom: .25em;

            p {
                color: #fff;
                margin: .25rem 0;
                font-size: .9rem;
                line-height: 1.2em;

                &.icon-left {
                    padding-left: 1.5em;
                    font-family: $primaryFont;
                    font-size: 1.5em;

                    &:before {
                        width: 1em;
                        height: 1em;
                    }
                }
            }
        }
    }

    .legend-line {
        p {
            color: #fff;
            font-size: 1rem;
        }
    }
}

@media (min-width: 1024px) {
    
    .legend-line {
        display: block;
    }

    .map-country-baloon {
        height: 100%;
        top: 0;
        bottom: unset;
        width: 40vw;

        .white-card { width: 30vw; }

        h2 {
            &:after {
                content: '';
                width: 90%;
                height: 1.25rem;
                left: 0;
                display: block;
                bottom: -.25rem;
                position: relative;
                z-index: 5;
                background-image: url(../assets/underline_title_lightblue-w.svg);
            }
        }
    }
}

@media (min-width: 1200px){
    .map-country-baloon {
        padding: 1rem 0rem;
        .white-card {
            padding: 1rem 0 2rem 0;
            margin-top: 2rem;
        }
    }
    .wg-map-info .box,
    .wg-map-info .box:first-child {
        margin: 2rem 0 0 auto;
    }
}

@media (min-width: 1500px) {
    .map-country-baloon h2 {
        margin-bottom: 2rem;
        margin-top: 3rem;
    }

    .map-country-baloon h2:after { bottom: -30px; }
    
    .map { height: 90vh; }

    .map-country-baloon {
        h2 {
            font-size: 4rem;
            position: relative;
        }

        .white-card {
            min-height: 20vh;
            width: 20vw;
            margin: 4rem 0rem 3rem;
            .didascalia { font-size: 1.75rem; }
        }
    }

    .legend-grid {
        width: 20vw;
    }
}
</style>
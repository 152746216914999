<template>
    <div :class="['gray-card', pinned ? 'pinned' : '']">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'gray-card',
        data: () => {
            return {}
        },
        props: {
            pinned: Boolean
        },
        methods: {},
        mounted() {}
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables.scss";

    .gray-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        //height: fit-content;
        height: 100%;
        background-color: $lightGray;
        padding: 1rem 1.5em;
        position: relative;

        &:before {
            top: unset;
            transform: none;
            left: unset;
            right: 0;
            bottom: 0;
        }

        .col1 {
            width: 100%;
            padding-right: 0rem;
        }

        .col2 {
            width: 100%;
            padding-top: 1rem;
            border-top: 2px darken($hoverGray, 10%) dashed;
        }

        &.pinned {
            position: relative;
            padding-top: 4rem;

            &:after {
                position: absolute;
                left: 1.5em;
                top: -2px;
                background-image: $vgradient;
                width: 23px;
                height: 32px;
                clip-path: polygon(0% 0%, 0% 100%, 50% 75%, 100% 100%, 100% 0%);
                content: '';
                display: block;
            }

            &::before {
                position: absolute;
                top: 10px;
                left: 6em;
                bottom: auto;
                display: block;
                content: "internship opportunity";
                font-size: 11px;
                text-transform: uppercase;
                font-weight: 500;
            }

        }
    }

    @media (min-width: 768px) {
        .gray-card {
            padding: 2rem;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .col1 {
                width: 70%;
            }

            .col2 {
                width: 25%;
                border-top: none;
                padding-top: 0;
                padding-left: 1rem;
                border-left: 2px darken($hoverGray, 10%) dashed;

            }

            &.pinned {
                
        &:after {
            left: 2em;
            top: -5px;
            width: 30px;
            height: 40px;
        }

        &::before {
            top: 10px;
            left: 7em;
        }

            }
        }
    }

    @media (min-width: 992px) {
        .col1 {
            width: 100%;
            padding-right: 2rem;
        }

    }
</style>
<template>
  <div class="page-layout details">

    <HeaderIntroImage underlined backstep :headerMetadata="pageData"></HeaderIntroImage>
    <div class="container">
      <div class="step" v-if="!loggedUser">
        <h3 class="gray">Enter your credentials</h3>
        <form class="login" @submit.prevent>
          <div class="campo">
            <label class="label bold" for="email">EMAIL</label>
            <input required type="email" id="qtedu-login-email" name="email" placeholder="Email"
              v-model="formData.email" @input="checkLogin('qtedu-login-email')">
          </div>
          <div class="campo">
            <label class="label bold" for="password">PASSWORD</label>
            <input required type="password" id="qtedu-login-password" name="password" placeholder="Password"
              v-model="formData.password">
          </div>
          <div>
            <vue-recaptcha class="recaptcha" ref="recaptcha" :sitekey="sitekey" @verify="onverify" @error="onerror"
              @expired="onexpired">
            </vue-recaptcha>
          </div>
          <div>
            <p v-if="this.error" class="error-message">{{ this.errorMessage }}</p>
            <button class="btn slim" type="submit" @click="processLogin"><span>Log In</span></button>
          </div>
        </form>

        <br>

        <button class="accordion" type="button" @click="toggleAccordion()">
          <span :class="['icon-right read-more', this.showRecover ? 'upside' : '']">Recover password</span>
        </button>

        <form :class="{'recover': true, 'hidden': this.recovering}" 
          v-if="this.showRecover && !this.recovering" 
          @submit.prevent validate>
          <div class="campo">
            <label class="label bold" for="recover-email">EMAIL</label>
            <input required type="text" name="recover-email" placeholder="Email" v-model="recoverData.email">
          </div>
          <button class="btn border slim" type="submit" @click="confirmRecover"><span>Recover</span></button>
        </form>

        <form :class="{'recover': true, 'hidden': !this.recovering}" 
          v-if="this.showRecover && this.recovering" 
          @submit.prevent validate>
          
          <p class="recover-message">If <span class="bold">{{ this.recoverData.email }}</span> is a valid account, an email will be sent with instructions to reset your password.</p>
          
          <div class="recover-buttons">
            <button class="btn border slim" type="submit" @click="cancelRecovering"><span>Cancel</span></button>
            <button class="btn slim" type="submit" @click="processRecover"><span>Send</span></button>
          </div>
        </form>

        <form id="login_back" target="_blank" :action="baseurl + 'user/login'" method="POST">
          <input type="hidden" name="name" value="" size="60" maxlength="60" required="required" aria-required="true"
            v-model="formData.email">
          <input type="hidden" name="pass" size="60" maxlength="128" required="required" aria-required="true"
            aria-invalid="true" v-model="formData.password">
          <input type="hidden" name="form_id" value="user_login_form">
        </form>

        <form id="recover_back" :action="baseurl + 'user/password'" method="POST">
          <input type="hidden" name="name" value="" size="60" maxlength="254" required="required" aria-required="true"
            v-model="recoverData.email">
          <input type="hidden" name="form_id" value="user_pass">
        </form>
      </div>

      <div class="step" v-if="loggedUser">
        <h3>You already logged in as {{ this.$store.state.logged_email }}.</h3>
        <a class="btn plain border" :href="dashboardlink" target="_blank"><span class="icon-right external">Go to your
            Dashboard</span></a>
      </div>

      <div class="step" v-if="!loggedUser">
        <WhiteCard class="gray-bg">
          <h3 class="gray">You don't already have an account?</h3>
          <h2>Register as a Guest</h2>
          <p>Registering through this form you will be able to submit all types of resources. Once registered all your
            submissions will be accessible from your personal profile. All content will be published after revision by
            the QTEdu Team. For information about the storage of your data, please visit our Privacy policy page and the
            Cookies policy page.</p>

          <form class="register" @submit.prevent validate>
            <div class="campo">
              <label class="label bold" for="name">EMAIL</label>
              <input required type="email" name="email" id="qtedu-register-email" placeholder="Email"
                v-model="registerData.email">
            </div>
            <div class="campo">
              <label class="label bold" name="confirm-email" for="confirm-email">CONFIRM EMAIL</label>
              <input required type="email" name="confirm-email" id="qtedu-register-confirmemail"
                v-model="registerData.confirmEmail" placeholder="Type your Email again">
            </div>

            <div>
              <vue-recaptcha class="recaptcha" ref="recaptchaR" :sitekey="sitekey" @verify="onverifyR" @error="onerrorR"
                @expired="onexpiredR">
              </vue-recaptcha>
            </div>

            <div>
              <p v-if="errorR" class="error-message">{{ this.registerErrMsg }}</p>
              <button class="btn slim" @click="processRegister"><span>Register</span></button>
            </div>
          </form>

          <form id="register_back" :action="baseurl + 'user/register'" method="POST">
            <input type="hidden" name="mail" value="" size="60" maxlength="254" required="required" aria-required="true"
              v-model="registerData.email">
            <input type="hidden" name="form_id" value="user_register_form">
          </form>

        </WhiteCard>
      </div>

      <div class="step">
        <div class="gray-card dark-bg become-member">
          <div class="col1">
            <h3>Become a member</h3>
            <p>
              Members of the QTEdu community will become part of a network of experts and professionals from all over
              Europe - and beyond- who have at heart the second
              Quantum revolution’s applications in Industry and society.<br>
              The first step is to register as a guest with your email address. You will, then, receive credentials to
              access the private area of the website. With
              those you can proceed to login. Choosing the “Become a member” option and filling in the form with all
              your personal information you will officially
              become a QTEdu community member.
            </p>
          </div>
          <div class="col2">
            <p>Members will be able to:</p>
            <ul class="list-1">
              <li>
                <p>
                  Benefit from networking opportunities and tools to find partners with whom to build fruitful
                  collaborations to design and develop material and resources;
                </p>
              </li>
              <li>
                <p>
                  Share projects and materials with other experts from Europe and beyond;
                </p>
              </li>
              <li>
                <p>
                  Discover funding opportunities;
                </p>
              </li>
              <li>
                <p>
                  Contribute to the evolution of the European roadmap for the education and training landscape and much
                  more!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
  import {
    VueRecaptcha
  } from 'vue-recaptcha'
  import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
  import WhiteCard from '../components/atomic-elements/WhiteCard.vue'
  export default {
    name: 'login',
    components: {
      HeaderIntroImage,
      WhiteCard,
      VueRecaptcha
    },
    props: {},
    data: () => {
      return {
        pageData: null,
        formData: {
          email: '',
          password: '',
          robot: false
        },
        recoverData: {
          email: ''
        },
        recovering: false,

        registerData: {
          email: '',
          confirmEmail: '',
          password: '',
          confirmPassword: '',
          robot: false
        },
        error: false,
        errorMessage: '',
        showRecover: false,

        errorR: false,
        /* registerEmailErr: false, 
        registerEmailMsg: '',
        registerPassErr: false,
        registerPassMsg: '', */
        registerErrMsg: ''
      }
    },
    computed: {
      sitekey() {
        return process.env.VUE_APP_RECAPTCHA
      },
      baseurl() {
        return process.env.VUE_APP_REDIRECT
      },
      dashboardlink() {
        return process.env.VUE_APP_REDIRECT + 'admin/dashboard'
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      username() {
        return this.$store.state.user_name
      }
    },
    methods: {
      onverify() {
       //console.log('recaptcha - VERIFIED')
        this.formData.robot = true
      },
      onexpired() {
       //console.log('recaptcha - EXPIRED')
        this.errorMessage = 'Recaptcha expired'
        this.formData.robot = false
      },
      onerror() {
       //console.log('recaptcha - ERROR')
        this.errorMessage = 'Recaptcha error'
        this.formData.robot = false
      },
      onverifyR() {
       //console.log('recaptcha - VERIFIED')
        this.registerData.robot = true
      },
      onexpiredR() {
       //console.log('recaptcha - EXPIRED')
        this.registerErrMsg = 'Recaptcha expired'
        this.registerData.robot = false
      },
      onerrorR() {
       //console.log('recaptcha - ERROR')
        this.registerErrMsg = 'Recaptcha error'
        this.registerData.robot = false
      },
      toggleAccordion() {
        this.showRecover = !this.showRecover
      },
      confirmRecover() {
        this.recovering = true
      },
      cancelRecovering() {
        this.recovering = false
      },
      goto() {
        this.$router.push('/get-involved')
      },
      checkLogin(id) {
        if (document.getElementById(id).checkValidity()) {
          this.error = false
          this.errorMessage = ''
        }
      },
      processLogin() {
        event.preventDefault()

        if (!document.getElementById('qtedu-login-email').checkValidity()) {
          this.error = true
          this.errorMessage = 'Invalid email'
          this.$refs.recaptcha.reset()

        } else if (!this.formData.robot) {
          this.error = true
          this.errorMessage = 'Recaptcha not verified'

        } else {
          var url = new URL(process.env.VUE_APP_REDIRECT + "user/login?_format=json");
          fetch(url, {
              method: "POST",
              redirect: "follow",
              body: JSON.stringify({
                "name": this.formData.email,
                "pass": this.formData.password
              })
            })
            .then(res => {
              //print error message if login not correct
              if (res.status != 200) {
                this.error = true
               //console.log('*** LOGIN ERROR ***', res)
              }

              //if login is successful, logs in also in the backend
              else {
                //console.log(res)
                this.error = false
                document.getElementById('login_back').submit()
              }

              return res.json()
            })
            .then(json => {
              //and saves in localStorage both tokens for logout, drupal jsonapi fetch and session
              if (this.error) {
                this.errorMessage = json.message
                this.$refs.recaptcha.reset()
                this.formData.robot = false
              } else {
                this.$store.commit('SET_LOGIN', json)
                this.formData.email = ''
                this.formData.password = ''
               //console.log('*** LOGGED IN ***')
              }
            })
        }
      },
      processRegister() {
        event.preventDefault()

        if (!document.getElementById('qtedu-register-email').checkValidity() || this.registerData.email == '') {
          this.errorR = true
          this.registerErrMsg = 'Invalid email'
          this.registerData.confirmEmail = ''
          this.$refs.recaptchaR.reset()

        } else if (this.registerData.email != this.registerData.confirmEmail) {
          this.errorR = true
          this.registerErrMsg = 'Emails do no match'
          this.$refs.recaptchaR.reset()

        } else if (!this.registerData.robot) {
          this.errorR = true
          this.registerErrMsg = 'Recaptcha not valid'

        } else {
          this.errorR = false
          this.registerErrMsg = ''
        }


        //PASSWORD CONTROL FOR REGISTER
        /*if(this.registerData.password != this.registerData.confirmPassword) {
          this.registerPassErr = true
          this.registerPassMsg = 'Passwords do not match'
          this.registerData.confirmPassword = ''
        } else if(this.registerData.password=='' && this.registerData.confirmPassword=='') {
          this.registerPassErr = true
          this.registerPassMsg = 'Can\'t use and empty password'
          this.registerData.confirmPassword = ''
        } else if(!this.registerData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
          this.registerPassErr = true
          this.registerPassMsg = 'Password must have at least 8 characters, one UPPERCASE letter and one lowercase letter'
          this.registerData.confirmPassword = ''
        } else {
          this.registerPassErr = false
          this.registerPassMsg = ''
        }*/

        if (!this.errorR && this.registerData.robot) {

          document.getElementById('register_back').submit()
          this.registerData.email = ''
          this.registerData.confirmEmail = ''

        }
      },
      processRecover() {
        event.preventDefault()
        document.getElementById('recover_back').submit()
        this.recoverData.email = ''
      },
    },
    mounted() {
      this.error = false
      this.errorMessage = ''
      this.showRecover = false
      this.errorR = false
      this.registerErrMsg = ''
      this.recovering = false

      this.pageData = {
        attributes: {
          title: "Login or Register",
          subtitle: "",
          tag: ""
        }
      }

      document.getElementById('qtedu-register-confirmemail').onpaste = e => e.preventDefault()
      document.getElementById('qtedu-login-password').onpaste = e => e.preventDefault()
    },
    watch: {}
  }
</script>

<style lang="scss" scoped>
  .hidden {
    height: 1px;
    opacity: 0;
    overflow: hidden;
  }

  .recover-message {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .recover .recover-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    button { margin: 0 .5em; }
  }

  .become-member {
    position: relative;
    padding: 1rem 2rem;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.5em;
      height: 100%;
      content: "";
      background: linear-gradient(to bottom, #FF7A07 20%, #FF0576 80%);
    }
  }

  .error-message {
    color: #FF0576;
    margin: 1em 0;
    font-size: 1rem;
  }

  .recaptcha ::v-deep div {
    margin: 1em auto 0 auto;
    max-width: 100% !important;

    * {
      max-width: 100% !important;
    }
  }

  .recover {
    .campo {
      margin: 1em 0;
    }

    .btn {
      margin: 0 0 0 auto;
    }
  }

  form.login,
  form.register,
  .recover {
    display: block;
    gap: 1em;
  }

  .backimage {
    padding: 2em;
    display: flex;
    flex-direction: column;
    background-image: url('/block_conference.jpg');
    background-size: cover;

    p {
      font-size: .95em;
    }
  }

  button[type=submit] {
    max-height: 2.25em;
  }

  .accordion {
    background: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-size: 1rem;
    padding: .5em 0;
    margin-bottom: 1rem;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 576px) {
    .recaptcha ::v-deep div {
      margin: 1em 0 0 0;
    }
  }

  @media (min-width: 1024px) {
    .become-member {
      padding: 1rem 3rem;
    }

    .recaptcha ::v-deep div {
      margin: 0;
    }

    .error-message {
      margin: 0 0 1em 0;
    }

    .gray-bg {
      padding: 3em;
    }

    .backimage {
      flex-direction: row;
      justify-content: space-between;

      .col1,
      .col2 {
        width: 48%;
      }

      .col2 .btn {
        margin: 2em 0 0 auto;
      }
    }

    form.login,
    form.register,
    .recover {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em 2em;

      .btn {
        margin: 0 0 0 auto;
      }
    }

    .recover {
      .campo {
        margin: 0;
      }

      .btn {
        margin: auto auto 0 0;
        top: 0;
      }
    }
  }
</style>
<template>
    <article class="project-min">
        <router-link :to="pjlink" class="tile plain">
            
            <div class="article--thumbnail">

                <ImageItem v-if="projectData.field_logo" :imagedata="projectData" 
                    fieldname="field_logo" 
                    :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                </ImageItem>
                
                <picture v-else>
                    <img src="../../assets/project-icon.png" alt="">
                </picture>

            </div>

            <div class="article--content">
                <h4 class="article--title">
                    {{ projectData.attributes.title + ' (' + projectData.attributes.field_acronym + ')' }}
                </h4>
                <p class="arrow icon-left"></p>
            </div>
        </router-link>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem.vue'
export default {
    name: 'project-card-min',
    components: {
        ImageItem
    },
    props: {
        projectData: Object,
        routesData: Array
    },
    data: () => {
        return {}
    },
    computed: {
        pjphoto() {
            if(this.projectData && this.projectData.field_logo)
                return process.env.VUE_APP_ENDPOINT + this.projectData.field_logo.attributes.uri.url
            else return require('../../assets/placeholder-2.png')
        },
        pjlink() {
            var url = this.routesData.filter(r => r.nid == this.projectData.attributes.drupal_internal__nid)
            if(this.projectData && url.length>0)
                return url[0].path
            else return '/not-found'
        }
    },
    methods: {},
    mounted() {
        //console.log(this.projectData)
    },
    watch: {}
}
</script>

<style lang="scss">

</style>
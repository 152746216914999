<template>
    <article class="generic">
        <router-link :to="detaillink" class="tile plain">
            <GrayCard 
                v-if="articleData && articleData.attributes"
                :pinned="pinned">

                <div class="col1"> 
                    <keep-alive v-if="articleData.field_category" >
                        <div v-if="articleData.field_category.attributes.name!='Other (specify)'"
                            class="article--tag label blue">
                            <span v-if="articleData.attributes.field_online_activity" class="bold">ONLINE</span>
                            {{ articleData.field_category.attributes.name }}
                        </div>
                    </keep-alive>

                    <h4 class="article--title gray">{{ articleData.attributes.title }}</h4>

                    <div class="article--teaser teaser" 
                        v-if="articleData.attributes.field_summary_and_description.summary">
                        <p>{{articleData.attributes.field_summary_and_description.summary}}</p>
                    </div>

                    <div class="article--meta">
                        <div class="targets">
                            <p v-for="(t, ind) in articleData.field_school_target" :key="'target_school_'+ind">
                                <span v-if="t.attributes.name != 'Other (specify)'" class="icon-left target">{{ t.attributes.name }}</span>
                            </p>
                            
                            <p v-for="(t, ind) in articleData.field_higher_education_target" :key="'target_higher_'+ind">
                                <span v-if="t.attributes.name != 'Other (specify)'" class="icon-left target">{{ t.attributes.name }}</span>
                            </p>
                            
                            <p v-for="(t, ind) in articleData.field_life_long_learning_target" :key="'target_lifelong_'+ind">
                                <span v-if="t.attributes.name != 'Other (specify)'" class="icon-left target">{{ t.attributes.name }}</span>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="col2">
                    <div class="label">COUNTRIES</div>
                    <p class="bold" v-if="articleData.attributes.field_country.length"> 
                        <span v-for="(l, ind) in articleData.attributes.field_country" :key="'lang'+ind">{{ 
                            organizationCountry(l.country) +
                            ((articleData.attributes.field_country.length-1 > ind) ? ', ' : '')
                        }}</span>
                    </p>
                    <p v-else class="bold"><span>Worldwide</span></p>

                    <div v-if="articleData.field_program_language.length" class="label">LANGUAGES</div>
                    <p class="bold"> 
                        <span v-for="(l, ind) in articleData.field_program_language" :key="'lang'+ind">{{ 
                            l.attributes.name +
                            ((articleData.field_program_language.length-1 > ind) ? ', ' : '')
                        }}</span>
                    </p>

                    <div class="label">PROVIDED BY</div>
                    <div v-for="(org, ind) in articleData.field_program_organization" :key="'org'+ind">
                        <p class="bold">{{ 
                            org.attributes.field_org_name + 
                            ((org.attributes.field_org_name && org.attributes.field_org_mat_prg_city) ? ', ' : '') +
                            (org.attributes.field_org_mat_prg_city ? org.attributes.field_org_mat_prg_city : '') +
                            ((org.attributes.field_org_mat_prg_country && (org.attributes.field_org_name || org.attributes.field_org_mat_prg_city)) ? ', ' : '') +
                            (org.attributes.field_org_mat_prg_country ? organizationCountry(org.attributes.field_org_mat_prg_country.country) : '')
                        }}</p>
                    </div>
                </div>
                
            </GrayCard>
        </router-link>
    </article>
</template>

<script>
import GrayCard from '../atomic-elements/GrayCard.vue'
export default {
    name: 'pct-card',
    components: {
        GrayCard
    },
    data: () => {
        return {}
    },
    props: {
        pinned: Boolean,
        articleData: Object,
        cat: Object,
        routesData: Array
    },
    computed: {
        organization() {
            return this.articleData.field_program_organization[0].attributes
        },
        detaillink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid )

            if(link.length>0)
                path = link[0].path
            else
                path = '/not-found'

            return path
        }
    },
    methods: {
        organizationCountry(code) { return this.$store.getters.getCountry(code) }
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from './components/layout-blocks/TheHeader'
import TheFooter from './components/layout-blocks/TheFooter'
import { fetchRoutes } from './libs/drupalClient'

  export default {
    name: 'App',
    components: {
      TheHeader,
      TheFooter
    },
    data: () => {
      return {
        metas: {}
      }
    },
    computed: {
      route() {
        return this.$route
      }
    },
    metaInfo() {
      return {
        title: /*this.metas.metadata ? (this.metas.metadata.title + ' | QTEdu') :*/ 'QTEdu',
        meta: [
          /*{
            name: 'description',
            content: this.metas.metadata ? this.metas.metadata.description : ''
          },*/
          {
            property: 'og:title',
            content: this.metas.metadata ? this.metas.metadata.title : ''
          },
          {
            property: 'og:site_name',
            content: 'QTEdu'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          /*{
            property: 'og:image',
            content: this.metas.metadata ? this.metas.metadata.image : (process.env.VUE_APP_ENDPOINT + '/social-image.jpg')
          },*/
          {
            name: 'robots',
            content: 'index,follow'
          }
        ] 
      }
    },
    methods: {
      async refreshPage() {
        //evento chiamato al refresh della pagina o al reindirizzamento di una route
        //per evitare il cambio di lingua non voluto

        //aggiorna STORE e LOCALSTORAGE (come helper di sessione) simultaneamente
        localStorage.setItem('LS_LANG_KEY', this.$store.state.currentLang)
        this.$store.commit('SET_CURRENT_LANG', localStorage.getItem('LS_LANG_KEY'))

        var logout_tk = localStorage.getItem('LS_QTEDU_LOGOUT_TOKEN')
        var csrf_tk = localStorage.getItem('LS_QTEDU_CSRF_TOKEN')
        //var logged_id = localStorage.getItem('LS_QTEDU_LOGGED_ID')
        
        if( logout_tk!=null && csrf_tk!=null && logout_tk!='' && csrf_tk!='' ) {
         //console.log('refresh login')
          this.$store.commit('SET_LOGIN', {
            logout_token: localStorage.getItem('LS_QTEDU_LOGOUT_TOKEN'),
            csrf_token: localStorage.getItem('LS_QTEDU_CSRF_TOKEN'),
            current_user: {
              uid: localStorage.getItem('LS_QTEDU_LOGGED_ID'),
              name: localStorage.getItem('LS_QTEDU_LOGGED_EMAIL')
            }
          })
        }
      },
      /*fetchMetas() {
        if(this.$route.fullPath=='/') {
          fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/home')
          .then(res => res.json())
          .then(json => {
            //console.log('FETCH META', json)
            json.metadata.title = "Homepage"
            this.metas = json
          })
        } else {
          fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=' + this.$route.fullPath)
          .then(res => res.json())
          .then(json => {
            //console.log('FETCH META', json)
            this.metas = json
          })
        }
       //console.log(this.metas)
      }*/
    },
    async mounted() {
     //console.log('** REFRESH PAGE **')

      await this.refreshPage()
      await fetchRoutes().then(r => this.$store.commit('SET_ROUTES', r.routes))
    },
    watch: {
      route() {
        this.refreshPage()
      }
    }
  }
</script>

<style lang="scss">
@import "style.scss";
</style>
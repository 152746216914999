<template>
    <article class="generic">
        <router-link :to="detaillink" class="tile plain">
            <GrayCard 
                v-if="articleData && articleData.attributes"
                :pinned="pinned">

                <div class="col1">
                    <div
                     :class="[
                        'article--tag', 'blue', 'icon-left', 
                        articleData.field_ref_working_group ? 'target' + articleData.field_ref_working_group.attributes.drupal_internal__nid : ''
                    ]">
                    {{ articleData.field_ref_working_group ? articleData.field_ref_working_group.attributes.title : '' }}
                    </div>
                    <h4 class="article--title gray">{{ articleData.attributes.title }}</h4>
                    <div class="article--teaser teaser" v-html="articleData.attributes.field_description.value"></div>
                </div>

                <div class="col2">
                    <p class="bold">{{ articleData.attributes.field_acronym }}</p>

                    <ImageItem v-if="articleData.field_logo" :imagedata="articleData" 
                        fieldname="field_logo" 
                        :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                    </ImageItem>
                    
                    <picture v-else>
                        <img src="../../assets/project-icon.png" alt="">
                    </picture>
                </div>
                
            </GrayCard>
        </router-link>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem.vue'
import GrayCard from '../atomic-elements/GrayCard.vue'
export default {
    name: 'project-card',
    components: {
        GrayCard,
        ImageItem
    },
    data: () => {
        return {
            
        }
    },
    props: {
        cat: Object,
        pinned: Boolean,
        articleData: Object,
        routesData: Array
    },
    computed: {
        detaillink() {
            var path = ''
            var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid )

            if(link.length>0)
                path = link[0].path
            else
                path = '/not-found'

            return path
        }
    },
    methods: {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
picture {
    margin: 0 auto;
    max-width: 200px;
    height: unset;
    display: block;
}
</style>
<template>
    <div class="filters-aside">
        <div @click="collapse" :class="['icon-right read-more filters--title mobile', !collapseFilters ? 'upside' : '']">
            <p class="icon-left filters label">FILTERS</p>
        </div>

        <p class="filters--title filters label icon-left desktop">FILTERS</p>
        
        <div :class="{ 'hidden': this.collapseFilters}">
            <slot></slot>
            <!--<button class="btn slim"><span>Apply</span></button>-->
            <button class="btn light slim" @click="reset()"><span class="icon-right reset">Reset</span></button>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'filters-aside',
    data: () => {
        return {
            collapseFilters: true
        }
    },
    components: {},
    props: {},
    computed: {},
    methods: {
        reset() {
            this.$emit('reset')
        },
        collapse() {
            this.collapseFilters = !this.collapseFilters
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
.upside:before { top: 1.25em; }
.desktop { display: none; }
.hidden { display: none; }

@media (min-width: 992px) {
    .mobile { display: none; }
    .desktop { display: block; }
    .hidden { display: block; }
}
</style>
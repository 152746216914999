<template>
    <div class="block-carousel">
        <!--<h3>BlocksCarousel.vue - Responsive VueSlickCarousel with multiple components inside</h3>-->
        <VueSlickCarousel
            @swipe="setCarouselSwiping(true)"
            @mouseup.native="setCarouselSwiping(false)"
            @touchend.native="setCarouselSwiping(false)"
            v-bind="carouselSettings" :class="[
                this.carouselSettings.dots ? 'dotted' : '', this.swiping ? 'swiping' : ''
            ]">
            <slot></slot>
        </VueSlickCarousel>
    </div>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    export default {
        name: 'block-carousel',
        components: {
            VueSlickCarousel,
        },
        props: {
            dotted: Boolean,
            toshow: Number,
            vw: Boolean,
            singleslide: Boolean
        },
        data: () => {
            return {
                swiping: false,
                carouselSettings: {
                    dots: true,
                    arrows: true,
                    focusOnSelect: true,
                    infinite: false,
                    speed: 200,
                    slidesToShow:3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    touchThreshold: 5,
                    responsive: [
                        {
                            "breakpoint": 1140,
                            "settings": {
                                "slidesToShow": 3,
                                arrows: true,
                            }
                        },
                        {
                            "breakpoint": 1024,
                            "settings": {
                                "slidesToShow": 1,
                                arrows: false,
                            }
                        },

                    ],
                },
            }
        },
        computed: {},
        methods: {
            setCarouselSwiping(state) {
                this.swiping = state;
            },
        },
        mounted() {
            if(this.singleslide) {
                this.carouselSettings.slidesToShow = 1
                this.carouselSettings.arrows = false
                this.carouselSettings.responsive = []
            }

            this.carouselSettings.variableWidth = this.vw
            window.addEventListener("resize", this.changeCarousel)
        },
        watch: {}
    }
</script>

<style lang="scss">
    @import '../../styles/variables.scss';

    .slick-slider {
        margin: 0 auto;
        margin-bottom: 3em;
        width: 98%;

        &.swiping {
            .slick-slide {
                pointer-events: none;
            }
        }

        &.dotted {
            width: 100%;
        }
    }

    .slick-slide>div {
        height: 100%;
    }

    .slick-slide {
        width: 100%;
    }

    .slick-prev,
    .slick-next {
        width: unset;
        height: unset;
        z-index: 4;

        &::before {
            color: $primaryColor !important;
            font-size: 32px !important;
            position: relative;
        }
    }

    .slick-dots {
        bottom: -1.5em;

        li,
        li.slick-active {
            button::before {
                color: $primaryColor;
                font-size: 10px;
            }
        }
    }

    .dark-bg {

        .slick-prev,
        .slick-next {
            &:before {
                color: #fff !important;
            }
        }

        .slick-dots {

            li,
            li.slick-active {
                button::before {
                    color: #fff;
                }
            }
        }
    }

    .internal-meetings {
        max-width: 80vw;
    }
        
    .internal-meetings + .block-carousel {
        .white-card {
            box-shadow: none;

            h4 {
                margin-top: 0;
            }
        }
    }

    @media (min-width: 768px) {
        .slick-slide>div {
            //width: 100%;
            height: 100%;
        }

        .homepage .block-carousel .slick-initialized .slick-slide {
            display: block;
            width: 100%;
        }



        /*.dotted .slick-slide > div {
        width: 95%;
        margin: auto;
    }*/
    }

    @media (min-width: 1024px) {
        .slick-slide {
            width: auto;
        }
    }
</style>
<template>
  <div class="page-layout details">

    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container">
      <br>
      <p>Thank you, your password is successfully set.</p>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'reset-confirmation',
  components: {
    HeaderIntroImage
  },
  props: {},
  data: () => {
    return {
        pageData: {
            attributes: {
                title: "Reset Confirmation"
            }
        }
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {},
  mounted() {},
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
</style>
<template>
    <article :class="['member', 'member-min', leaderlabels.length>0 ? 'leader': '']"
        v-if="memberData && memberData.attributes && isVisible">
            
        <router-link :to="personlink" 
        
            :class="{
                'tile': true, 
                'plain': true,
                'private-profile': !isClickable
            }" >

            <div class="article--intro">
                <div class="article--thumbnail col1">
                    
                    <ImageItem v-if="memberData.field_photo" :imagedata="memberData" 
                        fieldname="field_photo" 
                        :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                    </ImageItem>

                    <picture v-else>
                        <img src="../../assets/profile.jpg" alt="">
                    </picture> 

                </div>

                <div class="col2 right-margin">
                    <h4 class="article--title">{{ memberData.attributes.title }}</h4>
                    <p>{{ memberData.field_organization[0].attributes.field_org_name }}</p>
                </div>
            </div>

            <div class="article--tag blue">
                <div v-for="(l, ind) in this.leaderlabels" :key="'leader'+ind">{{ l }}</div>
            </div>
            
            <div class="article--content">
                
                <div class="article--teaser teaser">
                    <p>{{ memberData.field_organization[0].attributes.field_org_position }}</p>
                </div>

                <div class="article--meta">
                    <span class="label" v-if="memberData.field_organization[0].attributes.field_country">{{
                        this.$store.getters.getCountry(memberData.field_organization[0].attributes.field_country.country)     
                    }}</span>
                </div>
            </div>
            
        </router-link>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem'
export default {
    name: 'member-card-min',
    components:{
        ImageItem
    },
    props: {
        groupleader: Boolean,
        assistant: Boolean,
        coordinator: Boolean,
        wgcoordinator: Boolean,
        memberData: Object,
        uid: Object,
        routesData: Array
    },
    computed: {
        leaderlabels() {
            var ll = []
            
            if(this.groupleader)
                ll.push("GROUP LEADER")
            if(this.coordinator)
                ll.push("PROJECT COORDINATOR")
            if(this.assistant) 
                ll.push("WORKING GROUP ASSISTANT")
            if(this.wgcoordinator)
                ll.push("WORKING GROUP COORDINATOR")
            
            if(this.memberData) {
                if(this.memberData.attributes.field_coordinator_type==1)
                    ll.push("QTEdu COORDINATOR")
                else if(this.memberData.attributes.field_coordinator_type==2)
                    ll.push("QTEdu CO-COORDINATOR")
            }

            return ll
        },
        personlink() {
           var path = '/not-found'

            var link = this.routesData.filter(r => r.nid == this.memberData.attributes.drupal_internal__nid)

            if(link.length>0 && this.isClickable)
                path = link[0].path
            
            return path
        },
        loggedUser() {
            return this.$store.getters.getLogged
        },
        isVisible() {
            return this.loggedUser //LOGGED USER CAN SEE EVERYTHING (ALSO NOT CREATED YET --> visibility==null)
                || (!this.loggedUser //NOT LOGGED USER CAN SEE JUST FULL/LIMITED CREATED MEMBERS 
                    && this.memberData.attributes.field_member_data_visibility!='None' 
                    && this.memberData.attributes.field_member_data_visibility)
        },
        isClickable() {
            return this.loggedUser //LOGGED USER CAN ENTER ALL PROFILES
                || (!this.loggedUser //NOT LOGGED CAN ENTER ONLY ON FULL MEMBERS
                    && this.memberData.attributes.field_member_data_visibility=='Full'
                    && this.memberData.attributes.field_member_data_visibility)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>

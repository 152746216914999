<template>
    <article :class="['news', 'calltoaction', pastcall ? 'past' : '']" v-if="articleData && articleData.attributes">
        <router-link :to="detailLink" class="tile plain">
            <div class="article--thumbnail">
                <ImageItem v-if="articleData.field_image" :imagedata="articleData" fieldname="field_image"
                    :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                </ImageItem>
                <picture v-else>
                    <img :src="newsimg" alt="news cover placeholder">
                </picture>
            </div>

            <div class="article--content">
                <div v-if="cat.nid!=0" :class="['article--tag', 'icon-left', 'target'+cat.nid]">
                    CALL TO ACTION
                </div>
                <div v-else :class="['article--tag']">
                    CALL TO ACTION
                </div>

                <!--<div class="inner">-->
                <div class="article--meta date icon-right arrow">
                    <p v-html="buildDate(this.articleData.attributes.field_date)"></p>
                </div>
                <h4 class="article--title">
                    {{ articleData.attributes.title }}
                </h4>
                <!--<div class="article--teaser teaser">
                        <p v-html="articleData.attributes.field_description.value"></p>
                    </div>-->
                <div class="deadline article--meta date">
                    <p :class="{'deadline': true, 'past': pastcall}">DEADLINE </p>
                    <p v-html="buildDate(this.articleData.attributes.field_opportunity_deadline)"></p>
                </div>
                <!--</div>-->
            </div>

        </router-link>
    </article>
</template>

<script>
    import ImageItem from '../atomic-elements/ImageItem'
    export default {
        name: 'call-to-action-card',
        components: {
            ImageItem
        },
        data: () => {
            return {}
        },
        props: {
            articleData: Object,
            imageData: Object,
            cat: Object,
            routesData: Array,
            pastcall: Boolean,
            backimg: Object,
            fieldname: {
                default: 'field_banner_top',
                type: String
            },
        },
        computed: {
            detailLink() {
                var path = ''
                var link = this.routesData.filter(r => r.nid == this.articleData.attributes.drupal_internal__nid)
                if (link.length > 0)
                    path = link[0].path
                else path = '/not-found'

                return path
            },
            newsimg() {
                return require('../../assets/news-placeholder.jpg')
            },
            /* deadline() {
                var year = new Date(this.articleData.attributes.field_opportunity_deadline).toLocaleDateString("en", { year: 'numeric'}) 
                var month = new Date(this.articleData.attributes.field_opportunity_deadline).toLocaleDateString("en", { month: 'short'}) 
                var day = new Date(this.articleData.attributes.field_opportunity_deadline).toLocaleDateString("en", { day: 'numeric'}) 

                return '<span>' + day + ' ' + month+ '</span> ' + year
            },
            date() {
                var year = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { year: 'numeric'}) 
                var month = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { month: 'short'}) 
                var day = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { day: 'numeric'}) 

                return '<span>' + day + ' ' + month+ '</span> ' + year
            }, */
        },
        methods: {
            buildDate(date) {
                var year = new Date(date).toLocaleDateString("en", { year: 'numeric'}) 
                var month = new Date(date).toLocaleDateString("en", { month: 'short'}) 
                var day = new Date(date).toLocaleDateString("en", { day: 'numeric'}) 

                return '<span>' + day + ' ' + month+ '</span> ' + year
            }
        }
    }
</script>

<style lang="scss">
    @import '../../styles/variables.scss';

    .news-page .news .article--meta.date.deadline {
        color: #071152;

        p {
            color: #071152;
            font-size: 14px;
        }

        .deadline {
            background-color: #ff0475;
            display: inline-block;
            padding: 7px 14px;
            border-radius: 5px;
            width: auto;
            color: #fff;
            margin: auto auto auto auto;
            margin-left: 0;
            margin-top: 0;
            margin-right: 10px;

            * {
                color: #fff;
                padding: 0;
                font-size: 14px;
            }

            ::v-deep p {
                color: #071152;
                font-size: 14px;

                span {
                    margin-left: 5px;
                    color: #fff;
                }
            }

            &.past {
                background-color: #fff;
                color: #070A44;
                border: 1px solid #070A44;
            }
        }
    }
</style>
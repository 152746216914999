<template>
    <keep-alive>
        <!-- if it's a top level route menu, it is a link -->
        <div class="nav-item-content" v-if="item.children.length == 0">
            <router-link class="nav-link" :to="item.url" @click.native="closemenu">
                {{ item.label }}
            </router-link>
        </div>

        <div v-else>
            <!-- if has children is not clickable -->
            <div class="nav-item-content">
                <span class="nav-link dropdown">{{ item.label }}</span>
            </div>

            <!-- if it has children, create a list of sub links -->
            <!--<ul :class="['submenu', submenuOpen === index ? 'active' : '']" >-->
            <ul :class="['submenu', 'level'+level]">
                <li v-for="(child, index) of item.children" :key="index" >
                    
                    <!-- create a clickable link for every child -->
                    <div v-if="voicenum==3 && level==1 && index==0" >
                        <h4>
                            Work with the Community
                        </h4>
                        <p class="teaser">
                            We are bringing together quantum professionals from different walks of quantum education, research, and industry to help define the future of Europe’s quantum workforce as the second quantum revolution unfolds.
                        </p>
 
                    </div>

                    <div v-if="voicenum==3 && level==1 && index==0" class="nav-item-content">
                        <a href="https://qt.eu/about-quantum-flagship/jobs-quantum-technologies" target="_blank" class="link-standard nav-link"> 
                            Explore jobs' opportunities on qt.eu
                        </a>
                    </div>

                    <div v-if="voicenum==4 && level==1 && index==0" >
                        <h4>
                            Landscape of competences and skills
                        </h4>
                        <p class="teaser">
                            QTEdu CSA compiled a framework of competencies in Quantum Technologies in order to facilitate the planning and design of education and training projects. Discover it.
                        </p>
                    </div>
                    
                    <recursive-link @close-menu="closemenu" :item="child" :level="level+1"></recursive-link>
                </li>
            </ul>
        </div>

    </keep-alive>
</template>

<script>
export default {
    name: 'recursive-link',
    props: {
        item: Object,
        level: Number,
        voicenum: Number
    },
    methods: {
        closemenu() {
            this.$emit('close-menu')
        },
        opensubmenu() {
            this.$emit('open-submenu')
        }
    }
}
</script>

<style lang="scss">
header.navigation .navbar .container .menu-list .container .navbar-nav > .nav-item:nth-child(3) .submenu.level1 > li:first-child {
    margin-top: 20px;
    margin-bottom: 15px;
}

header.navigation .navbar .container .menu-list .container .navbar-nav > .nav-item:nth-child(3) .submenu.level1 > li:first-child .nav-item-content{
    padding: 7px 0.5em 8px 0.5em;
}

header.navigation .navbar .container .menu-list .container .navbar-nav > .nav-item:nth-child(3) .submenu.level1 > li:first-child .nav-item-content a{
    color: #333;
}


/*
CUSTOM MENU ORDER IN FIRTS COLUMN 
work with the community 
*/

header.navigation .nav-item:nth-child(3) .submenu.level1 > li{
     display: flex !important;
    flex-direction: column;
 
    //testo
    >div:nth-child(1){
        order: 1 !important;    
    }

    //link esterno
    >div:nth-child(2){
        order: 3 !important;
    }

    //join btn
    >div:nth-child(3){
        order: 2 !important;        
    }
}

</style>